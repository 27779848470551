import { formatDate } from '@/helpers/filters.js'
import { parse } from 'date-fns'

export const strict = false;

export const state = () => ({
  query: {
    page: 1,
    input: ''
  },
  result: [],
})

export const actions = {
  getSearchResult(context, query) {
    var loadingObj = {
      content: 'search',
      type: 'new'
    };
    if (query.page > 1) {
      loadingObj.type = 'map'
    }
    context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$axios.get(process.env.API_URL + '/search?page=' + query.page + '&q=' + query.input + '&filter[status]=published')
    .then(response => {
      var paginationObj = {
        content: 'search',
        pagination: response.data.meta.pagination
      };
      context.commit('loadstateStore/setPagination', paginationObj, { root: true });
      context.commit('setResult', response.data);        
      loadingObj.type = 'done';
      context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },
}

export const getters = {
  result(state) {
    return state.result;
  }
}

export const mutations = {
  setResult(state, data) {
   var results = data.data;

   results.forEach(function(element) { 
    var result = element.attributes;
    result.title = result.title.replace("\\", "");
     if (result.products) {
      result.products.forEach(function(element) {
        // date comes in DD/MM/YY format and parsed here to Date. Native js expect MM/DD/YY
        result.date = formatDate(parse(element.pivot_data.active_from, 'd/M/yy', new Date()));
      });
    }
    if (result.product) {
      // date comes in DD/MM/YY format and parsed here to Date. Native js expect MM/DD/YY
      result.date = formatDate(parse(result.product.active_from, 'd/M/yy', new Date()));
    }
    if (result.videos.free_film) {
      result.video_free = result.videos.free_film[0];
      result.video_free.duration = result.video_free.duration.substring(3, 5);
    }
    if (result.videos.film) {
      result.video_paid = result.videos.film[0];
      result.video_paid.duration = result.video_paid.duration.substring(3, 5);
    }
    if (result.videos.trailer) {
      result.trailer = result.videos.trailer[0];
    }
  });
   
   if (data.meta.pagination.current_page == 1) {
     state.result = results;
   } else {
    state.result = state.result.concat(results);
  }
},

setQuery(state, set) {
  state.query[set.type] = set.name;
},
}
