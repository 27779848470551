import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';

export const strict = false;

export const state = () => ({
  categories: [],
  category: [],
  randomcategories: [],

  categoriesQuery: {
    include: 'images:types(thumb):limit(1|0)',
    page: 1,
    count: 50,
    sort: {
      recommended_at: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },

  sort: 'Populair'
})

export const actions = {
  async getCategories({commit}, query) {
    if (query.page == 1) {
      var loadingObj = {
        content: 'categories',
        type: 'new'
      };
    } else {
      var loadingObj = {
        content: 'categories',
        type: 'map'
      };
    }
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);    

    await this.$axios.get(process.env.API_URL + '/categories?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort)
    .then(response => {
      if (query.type && query.type == 'random') {
        return commit('setRandomCategories', response.data);          
      } 
      if (query.page == 1) {
        commit('setAllCategories', response.data);        
        loadingObj.type = 'done';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
      }else {
        commit('mapAllCategories', response.data);        
        loadingObj.type = 'done';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
      }

    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  },   

  async getCategory({commit}, query) {
    var loadingObj = {
      content: 'category',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    var videoLoadingObj = {
      content: 'categoryVideos',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', videoLoadingObj, { root: true });
    commit('videoStore/resetQuery', '', { root: true });

    let filters = serialize.serializeFilter(query.filter);    
    await this.$axios.get(process.env.API_URL + '/categories/' + query.slug + '?include=' + query.include + filters)
    .then(response => {
      commit('setCategory', response.data);
      loadingObj.type = 'done'
      commit('loadstateStore/setLoadState', loadingObj, { root: true });    
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  },

  async getVideos({commit}, query) {
    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filter);    
    await this.$axios.get(process.env.API_URL + '/videos?page=' + query.page + '&count=' + query.count + '&type=' + query.api_type + '&include=' + query.include + filters + sort + '&frontend=' + process.env.FRONTEND_ID)
    .then(response => { 
      response.data.category_id = query.filter.categories;
      commit('setVideosToRandomCat', response.data);        
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });  
  },  

}

export const getters = {
  categories(state) {
    return state.categories;
  },
  category(state) {
    return state.category;
  },
  categoriesQuery(state) {
    return state.categoriesQuery;
  },
  randomcategories(state) {
    return state.randomcategories;
  }
}

export const mutations = {
  setAllCategories(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var categories = deserialize(dataObj);

    categories.forEach(function(category) { 
      category.title = category.name;
      category.images.forEach(function(element) {  
        if (element.types == 'thumb') {
          category.thumb = element.sizes;
        }
      });
    })

    state.categories = categories;
  },
  mapAllCategories(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    
    var categories = state.categories.concat(deserialize(dataObj));

    state.categories = categories;
  },

  setRandomCategories(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var categories = deserialize(dataObj);    
    // categories.forEach(function(model) { 
    //   categories.videos.forEach(function(element) { 
    //     element.thumb = element.images[0].sizes;
    //   });
    // });

    state.randomcategories = categories;
  },
  setVideosToRandomCat(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var videos = deserialize(dataObj);
    videos.forEach(function(video) {
      video.images.forEach(function(element) { 
        if (element.types == 'thumb') {
          video.thumb = element.sizes;
        }
      });
    });
    var categories = state.randomcategories

    categories.forEach(function(category) {
      if(data.category_id == category.id) {
        category.videos = videos;
      } else {

      }
    })
    return state.randomcategories = categories;
  },
  setCategory(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var category = deserialize(dataObj);

    category.name = category.name.replace("\\", "");

    category.short = {};
    category.short.mobile = category.description.substring(0, 250) + '... <a>Lees meer</a>';
    category.short.tablet = category.description.substring(0, 50) + '... <a>Lees meer</a>';
    category.short.desktop = category.description.substring(0, 250) + '... <a>Lees meer</a>';

    state.category = category;
  },
  updateQuery(state, query) {
    if (query.type == 'sort') {
      state.categoriesQuery.sort = false;
      state.categoriesQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.categoriesQuery.page = query.name;
      if (query.name > 1) {
        state.categoriesQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.categoriesQuery.page = query.name;
    } else if(!query.name && !query.id) {
      state.categoriesQuery.filter = {
        status: 'published',
        products: '1,2',
        types: 'category'
      }
    } else {
      state.categoriesQuery.filter[query.type] = query.id;
    }
  },
  updateSortState(state, name) {
    state.sort = name;
  }
}