export const strict = false;

export const state = () => ({
  loadState: {
    allVideos: '',
    topVideos: '',
    recommendedVideos: '',
    videos: '',
    video: '',
    related: '',
    votw: '',
    categories: '',
    models: '',
    model: '',
    shows: '',
    show: '',
    cams: '',
    epg: '',
    search: '',
    favorites: '',
    account: '',
    votw_new: '',
    votw_free: '',
    showVideos: ''
  },
  pagination: {
    videos: '',
    models: '',
    shows: '',
    cams: '',
    search: ''
  },
})

export const actions = {


}

export const getters = {
  getLoadState(state) {
    return state.loadState;
  },
  getPagination(state) {
    return state.pagination;
  }
}

export const mutations = {
  setLoadState(state, loadingObj) {
    state.loadState[loadingObj.content] = loadingObj.type;
  },
  setPagination(state, paginationObj) {
    state.pagination[paginationObj.content] = paginationObj.pagination;
  }
}