import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';

export const state = () => ({
  collection: [],
  resource: [],

  query: {
    loadType: 'collection',
    include: 'images:types(thumb):limit(1|0)',
    page: 1,
    count: 50,
    sort: {
      recommended_at: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },
  loading: false,
  pagination: false
})

export const actions = {
  async get({commit}, query) {
    if (query.page == 1) {
      var loadingObj = {
        content: 'collection',
        type: 'new'
      };
    } else {
      var loadingObj = {
        content: 'collection',
        type: 'map'
      };
    }
    commit('setLoading', loadingObj);

    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);    

    await this.$axios.get(process.env.API_URL + '/others?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&type=friend' + '&frontend_id=1')
    .then(response => { 
      var paginationObj = {
        content: 'collection',
        pagination: response.data.meta.pagination
      };
      commit('setPagination', paginationObj);
      commit('setCollection', response.data);        
      loadingObj.type = 'done';
      commit('setLoading', loadingObj);
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('setLoading', loadingObj);
      throw error;
    });
  },   

}

export const getters = {
  collection(state) {
    return state.collection;
  },
  resource(state) {
    return state.resource;
  },
  loading(state) {
    return state.loading;
  },
  pagination(state) {
    return state.pagination;
  },
  query(state) {
    return state.query;
  }
}

export const mutations = {
  setCollection(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var collection = deserialize(dataObj);    

    collection.forEach(function(resource) {
      resource.title = resource.title.replace("\\", "");
      resource.short = {};
      resource.short.tablet = resource.description.substring(0, 170) + '... <a>Lees meer</a>';
      resource.short.desktop = resource.description.substring(0, 170) + '... <a>Lees meer</a>';
    })

    state.collection = collection;
  },
  setResource(state, data) {  
    state.resource = data;
  },

  setLoading(state, loadingObj) {
    state.loading = loadingObj.type;
  },
  setPagination(state, paginationObj) {
    state.pagination = paginationObj.pagination;
  },

  updateQuery(state, query) {
    if (query.type == 'sort') {
      state.query.sort = false;
      state.query.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.query.page = query.name;
      if (query.name > 1) {
        state.query.type = 'map';
      }
    } else if(query.type == 'page') {
      state.query.page = query.name;
    } else if(!query.name && !query.id) {
      state.query.filter = {
        status: 'published',
        products: '1,2',
        types: 'friend'
      }
    } else {
      state.query.filter[query.type] = query.id;
    }
  },
}