import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faCircleInfo as fortawesomefreesolidsvgicons_faCircleInfo } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleInfo)

    import  { faCircleCheck as fortawesomefreesolidsvgicons_faCircleCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleCheck)

    import  { faCheck as fortawesomefreesolidsvgicons_faCheck } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCheck)

    import  { faMagnifyingGlass as fortawesomefreesolidsvgicons_faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMagnifyingGlass)

    import  { faArrowUpRightFromSquare as fortawesomefreesolidsvgicons_faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowUpRightFromSquare)

    import  { faArrowLeft as fortawesomefreesolidsvgicons_faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowLeft)

    import  { faArrowRight as fortawesomefreesolidsvgicons_faArrowRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowRight)

    import  { faAngleLeft as fortawesomefreesolidsvgicons_faAngleLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleLeft)

    import  { faAngleRight as fortawesomefreesolidsvgicons_faAngleRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleRight)

    import  { faPlay as fortawesomefreesolidsvgicons_faPlay } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlay)

    import  { faPause as fortawesomefreesolidsvgicons_faPause } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPause)

    import  { faClock as fortawesomefreesolidsvgicons_faClock } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faClock)

    import  { faCircle as fortawesomefreesolidsvgicons_faCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircle)

    import  { faRightToBracket as fortawesomefreesolidsvgicons_faRightToBracket } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faRightToBracket)

    import  { faUserPlus as fortawesomefreesolidsvgicons_faUserPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUserPlus)

    import  { faSortUp as fortawesomefreesolidsvgicons_faSortUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortUp)

    import  { faSortDown as fortawesomefreesolidsvgicons_faSortDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSortDown)

    import  { faXmark as fortawesomefreesolidsvgicons_faXmark } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faXmark)

    import  { faCircleXmark as fortawesomefreesolidsvgicons_faCircleXmark } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleXmark)

    import  { faStar as fortawesomefreesolidsvgicons_faStar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStar)

    import  { faStarHalf as fortawesomefreesolidsvgicons_faStarHalf } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faStarHalf)

    import  { faHeart as fortawesomefreesolidsvgicons_faHeart } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHeart)

    import  { faBolt as fortawesomefreesolidsvgicons_faBolt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBolt)

    import  { faEnvelope as fortawesomefreesolidsvgicons_faEnvelope } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEnvelope)

    import  { faVideo as fortawesomefreesolidsvgicons_faVideo } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faVideo)

    import  { faFilm as fortawesomefreesolidsvgicons_faFilm } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faFilm)

    import  { faAngleUp as fortawesomefreesolidsvgicons_faAngleUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleUp)

    import  { faAngleDown as fortawesomefreesolidsvgicons_faAngleDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faAngleDown)

    import  { faSort as fortawesomefreesolidsvgicons_faSort } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSort)

    import  { faPlus as fortawesomefreesolidsvgicons_faPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlus)

    import  { faMinus as fortawesomefreesolidsvgicons_faMinus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faMinus)

    import  { faTags as fortawesomefreesolidsvgicons_faTags } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTags)

    import  { faPersonDress as fortawesomefreesolidsvgicons_faPersonDress } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPersonDress)

    import  { faHouse as fortawesomefreesolidsvgicons_faHouse } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faHouse)

    import  { faTv as fortawesomefreesolidsvgicons_faTv } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTv)

    import  { faBookOpen as fortawesomefreesolidsvgicons_faBookOpen } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBookOpen)

    import  { faLock as fortawesomefreesolidsvgicons_faLock } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLock)

    import  { faLockOpen as fortawesomefreesolidsvgicons_faLockOpen } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLockOpen)

    import  { faArrowsRotate as fortawesomefreesolidsvgicons_faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowsRotate)

    import  { faSpinner as fortawesomefreesolidsvgicons_faSpinner } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSpinner)

    import  { faCalendar as fortawesomefreesolidsvgicons_faCalendar } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendar)

    import  { faCalendarDays as fortawesomefreesolidsvgicons_faCalendarDays } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendarDays)

    import  { faExclamation as fortawesomefreesolidsvgicons_faExclamation } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExclamation)

    import  { faInfo as fortawesomefreesolidsvgicons_faInfo } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faInfo)

    import  { faPen as fortawesomefreesolidsvgicons_faPen } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPen)

    import  { faBagShopping as fortawesomefreesolidsvgicons_faBagShopping } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBagShopping)

    import  { faTriangleExclamation as fortawesomefreesolidsvgicons_faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTriangleExclamation)

    import  { faCircleArrowRight as fortawesomefreesolidsvgicons_faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleArrowRight)

    import  { faCaretUp as fortawesomefreesolidsvgicons_faCaretUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretUp)

    import  { faCaretDown as fortawesomefreesolidsvgicons_faCaretDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretDown)

    import  { faCaretRight as fortawesomefreesolidsvgicons_faCaretRight } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCaretRight)

    import  { faCircleArrowLeft as fortawesomefreesolidsvgicons_faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleArrowLeft)

    import  { faPhone as fortawesomefreesolidsvgicons_faPhone } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPhone)

    import  { faGift as fortawesomefreesolidsvgicons_faGift } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faGift)

    import  { faChevronDown as fortawesomefreesolidsvgicons_faChevronDown } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronDown)

    import  { faChevronUp as fortawesomefreesolidsvgicons_faChevronUp } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faChevronUp)

    import  { faTwitter as fortawesomefreebrandssvgicons_faTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTwitter)

    import  { faCircle as fortawesomefreeregularsvgicons_faCircle } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCircle)

    import  { faCalendarAlt as fortawesomefreeregularsvgicons_faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCalendarAlt)

    import  { faStar as fortawesomefreeregularsvgicons_faStar } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faStar)

    import  { faHeart as fortawesomefreeregularsvgicons_faHeart } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faHeart)

    import  { faEye as fortawesomefreeregularsvgicons_faEye } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faEye)

    import  { faClock as fortawesomefreeregularsvgicons_faClock } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faClock)

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)
Vue.component('fa-layers-text', FontAwesomeLayersText)
