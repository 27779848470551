import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
import { format, addDays, getUnixTime } from 'date-fns'

export const strict = false;

export const state = () => ({
  epg: [],
  epgNow: [],
})

export const actions = {
  async getEpg(context, query) {
    var loadingObj = {
      content: 'epg',
      type: 'new'
    };
    context.commit('loadstateStore/setLoadState', loadingObj, { root: true });

    var now = new Date();
    query.filters.start_datetime = format(now, 'dd-MM-yyyy HH:mm');
    query.filters.end_datetime = format(addDays(now, 1), 'dd-MM-yyyy HH:mm');

    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);

    await this.$axios.get(process.env.API_URL + '/epg?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&playout=' + query.playout)
    .then(response => {
      context.commit('setEpg', response.data)
      loadingObj.type = 'done';
      context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      console.log(error)
      loadingObj.type = 'error';
      context.commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },
}

export const getters = {
  epg(state) {
    return state.epg;
  },
  epgNow(state) {
    return state.epgNow;
  }
}

export const mutations = {
  setEpg(state, data) {
   var dataObj = [];
   dataObj.included = data.included;
   dataObj.data = data.data;
   var epg = deserialize(dataObj);

  //  moment.locale('nl'); // locale options was not used
   let currentTime = getUnixTime(new Date());
   let endtime = getUnixTime(new Date(epg[0].end_datetime));
   let startime = getUnixTime(new Date(epg[0].start_datetime));

   let getTotalPerc = endtime - startime;
   let progress = endtime - currentTime;

   let percentageLeft = progress / getTotalPerc * 100;
   let percentageDone = 100 - percentageLeft;
   let totalProgress = Math.round(percentageDone);

   epg.forEach((item) => {
    item.progress = totalProgress;

    item.start_datetime = format(new Date(item.start_datetime), "HH:mm");
    item.end_datetime = format(new Date(item.end_datetime), "HH:mm");

    if (item.resources) {
      item.resources.images.forEach(function(element) {
        if (element.types == 'thumb') {
          item.thumb = element.path;
        }
      });
      item.tagline = item.resources.tagline;
      item.slug = item.resources.slug;
      item.type = item.resources.type;
    } else {
      item.tagline = 'Exclusief voor TV';
      item.slug = false;
      item.type = 'video';
      // item.thumb = this.$config.errorimg;
    }
  });
   if (epg.length > 2) {
     state.epg = epg;
   } else {
    state.epgNow = epg;
  }
},
}