export default function ({ route, redirect, app }) {
  if (!route.query.ref) {
    return redirect({
      path: route.path,
      query: {
        ...route.query,
        ref: 'vod_update',
      },
    });
  }
}
