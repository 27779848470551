  /*
  ** VUEX Store for loading MXM sources trough FE
  */

  import { deserialize } from 'jsonapi-deserializer';

  export const strict = false;

  export const state = () => ({
    stream: [],
    killStream: false,
    streamStatus: [],
    socketToken: false,
    socketStatus: false,
    playerState: {
      status: 'stop',
      type: false,
      fullscreen: false
    },
    videoOptions: {
      autoplay: false,
      poster: false,
    },
  })

  export const actions = {   
    getFreeStream(context, query) {
      this.$axios.get(process.env.API_URL + '/free-stream?resource_id=' + query.resource_id + '&video_id=' + query.video_id)
      .then(response => { 
        response.data.data.attributes.type = query.type;
        response.data.data.attributes.location = query.location;
        if(query.type == 'preroll') {
          response.data.data.attributes.preroll_info = {};
          response.data.data.attributes.preroll_info.href = query.href;
          response.data.data.attributes.preroll_info.target = query.target;
        }
        context.commit('setStream', response.data);
      }).catch((error) => {
        console.log(error);
      });  
    },
    getPaidStream(context, query) {
      let ppm = '';
      if (query.credit_user_subscription_id) {
        ppm = '&credit_user_subscription_id=' + query.credit_user_subscription_id;    
      }
      if (query.phone_stream_id) {
        ppm = '&phone_stream_id=' + query.phone_stream_id;
      }
      this.$axios.get(process.env.API_URL + '/paid-stream?resource_id=' + query.resource_id + '&subscription_id=' + query.subscription_id + '&video_id=' + query.video_id + '&user_id=' + query.user_id + ppm)
      .then(response => { 
        response.data.data.attributes.type = query.type;
        response.data.data.attributes.location = query.location;
        context.commit('setStream', response.data);
      }).catch((error) => {
        if (query.phone_stream_id || query.credit_user_subscription_id) {
          context.commit('setKillStream', true);
        }
        console.log(error);
      });  
    },  
    authSocket(context, data) {
      this.$axios.post(process.env.SOCKET_URL + '/login', {
        'frontend_id': data.frontend_id,
        'user_ip': '127.0.0.1 ',
        'key': this.$config.socketKey,
        'id': data.stream_id,
        'url': data.url
      })
      .then(response => { 
        context.commit('setSocketToken', response);
      }).catch((error) => {
        console.log(error);
        context.commit('setSocketStatus', 'error');
      });  
    },
    errorSocket(context, data) {
      this.$axios.post(process.env.API_URL + '/stream/socket-log', {
        'stream_id': data.stream_id,
        'error': data.error
      })
      context.commit('setSocketStatus', 'error');
    },
    socketConnected(context, bool) {
      if (bool) {
        context.commit('setSocketStatus', 'connected');
      }
    },
    updateFreeStream(context, data) {
      if (data.stream_id === 1) {
        return;
      }
      this.$axios.get(process.env.API_URL + '/free-stream/' + data.stream_id + '/' + data.action)
      .then(response => { 
        context.commit('setStreamState', response);
      }).catch((error) => {
        console.log(error);
      });  
    },
    updateStream(context, data) {
      this.$axios.get(process.env.API_URL + '/paid-stream/' + data.stream_id + '/' + data.action)
      .then(response => { 
        context.commit('setStreamState', response);
        context.commit('setKillStream', false);
      }).catch((error) => {
        context.commit('setKillStream', true);
      });
    },
  }
  export const getters = {
    stream(state) {
      return state.stream;
    },
    killStream(state) {
      return state.killStream;
    },
    socketToken(state) {
      return state.socketToken;
    },
    streamStatus(state) {
      return state.streamStatus;
    },
    socketStatus(state) {
      return state.socketStatus;
    },
    playerOptions(state) {
      return state.playerOptions;
    },
    playerState(state, status) {
      return state.playerState;
    },
  }

//alleen mutations kan een staat aanpassen
export const mutations = {
  setStream(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    state.stream = deserialize(dataObj);    
  },
  setStreamByUrl(state, data) {
    state.stream = data;    
  },
  setKillStream(state, action) {
    state.killStream = action;    
  },
  setStreamState(state, data) {
    state.streamState = data.data;    
  },
  setSocketStatus(state, data) {
    state.socketState = data;    
  },
  setSocketToken(state, data) {
    state.socketToken = data.data.token;    
  },
  setStreamStatus(state, data) {
    state.streamState = data.data;    
  },
  updatePlayerOptions(state, data) {
    state.playerOptions = data;        
  },
  setPlayerStatus(state, status) {
    state.playerState.status = status;
  },
  setPlayerType(state, type) {
    state.playerState.type = type;
  },
  setPlayerFullscreen(state, todo) {
    state.playerState.fullscreen = todo;
  },
  clearStream(state) {
    state.stream = [];
    state.killStream = false;
    state.streamStatus = [];
    state.socketToken = false;
    state.socketStatus = false;
    state.playerState.status = 'stop';
    state.playerState.type = false;
    state.videoOptions.autoplay = false;
    state.videoOptions.poster = false;
  },
  clearStreamForPreroll(state) {
    state.stream = [];
    state.killStream = false;
    state.streamStatus = [];
    state.socketToken = false;
    state.socketStatus = false;
    // state.playerState.status = 'stop';
    // state.playerState.type = false;
    // state.videoOptions.autoplay = false;
    // state.videoOptions.poster = false;
  }
}