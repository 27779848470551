import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';

export const strict = false;

export const state = () => ({
  models: [],
  model: [],
  randommodels: [],

  filterModels: [],

  modelsQuery: {
    include: 'images:types(square):limit(1|0),products,categories',
    page: 1,
    count: 16,
    sort: {
      recommended_at: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },
  sort: 'Populair'
})

export const actions = {
  async getModels({commit}, query) {
    if (query.page == 1) {
      var loadingObj = {
        content: 'models',
        type: 'new'
      };
    } else {
      var loadingObj = {
        content: 'models',
        type: 'map'
      };
    }
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);    
    await this.$axios.get(process.env.API_URL + '/models?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&video_images=thumb&frontend=' + process.env.FRONTEND_ID)
    .then(response => { 
      var paginationObj = {
        content: 'models',
        pagination: response.data.meta.pagination
      };
      commit('loadstateStore/setPagination', paginationObj, { root: true });
      if (query.type && query.type == 'random') {
        commit('setRandomModels', response.data);          
      }
      commit('setAllModels', response.data);        
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  },   

  async getModel({commit}, query) {
    var loadingObj = {
      content: 'model',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    var videoLoadingObj = {
      content: 'modelVideos',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', videoLoadingObj, { root: true });
    commit('videoStore/resetQuery', '', { root: true });

    let filters = serialize.serializeFilter(query.filter);    
    await this.$axios.get(process.env.API_URL + '/models/' + query.slug + '?include=' + query.include + '&video_type=' + query.video_type + filters + '&frontend=' + process.env.FRONTEND_ID)
    .then(response => {
      commit('setModel', response.data);
      loadingObj.type = 'done'
      commit('loadstateStore/setLoadState', loadingObj, { root: true });    
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  },

  async getFilterModels({commit}, query) {
    await this.$axios.get(process.env.API_URL + '/models-list')
    .then(response => { 
      commit('setFilterModels', response.data);        
    }).catch((error) => {

    });
  },   

}

export const getters = {
  models(state) {
    return state.models;
  },
  model(state) {
    return state.model;
  },
  modelsQuery(state) {
    return state.modelsQuery;
  },
}

export const mutations = {
  setAllModels(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var models = deserialize(dataObj);    

    models.forEach(function(model) { 
      model.images.forEach(function(element) { 
        if (element.types == 'square') {
          model.thumb = element.sizes;
          model.thumb.original = element.path;
        }
        if (element.types == 'square_mobile') {
          model.thumb_mobile = element.sizes;
          model.thumb_mobile.original = element.path;
        }
      });
    });

    models.pagination = data.meta.pagination;
    
    if (data.meta.pagination.current_page > 1) {
      state.models = state.models.concat(models);
    }
    if (data.meta.pagination.current_page === 1) {
      state.models = models;
    }
  },

  setRandomModels(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var models = deserialize(dataObj);    
    models.forEach(function(model) { 
      model.videos.forEach(function(element) { 
        element.thumb = element.images[0].sizes;
      });
    });

    state.randommodels = models;
  },

  setModel(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var model = deserialize(dataObj);

    model.title = model.title.replace("\\", "");

    model.short = {};
    model.short.mobile = model.description.substring(0, 400) + '... <a>Lees meer</a>';
    model.short.tablet = model.description.substring(0, 50) + '... <a>Lees meer</a>';
    if(model.description.length > 750) {
      model.short.desktop = model.description.substring(0, 750) + '... <a>Lees meer</a>';
    }

    state.model = model;
  },

  setFilterModels(state, data) {
    state.filterModels = data;    
  },

  updateQuery(state, query) {
    if (query.type == 'sort') {
      state.modelsQuery.sort = false;
      state.modelsQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.modelsQuery.page = query.name;
      if (query.name > 1) {
        state.modelsQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.modelsQuery.page = query.name;
    } else if(!query.name && !query.id) {
      state.modelsQuery.filter = {
        status: 'published',
        products: '1,2',
        types: 'model'
      }
    } else {
      state.modelsQuery.filter[query.type] = query.id;
    }
  },
  updateSortState(state, name) {
    state.sort = name;
  },
}