// Use to check if a slide is active

export const strict = false;

export const state = () => ({
  slideNewSex: 1
})

export const getters = {
  slideNewSex(state) {
    return state.slideNewSex;
  }
}

// export const mutations = {
//   setSlide(state, slideObj) {
//     if (slideObj.slide == 'prev') {
//       return state[slideObj.type] = state[slideObj.type] - 1;
//     } else if (slideObj.slide == 'next') {
//       return state[slideObj.type] = state[slideObj.type] + 1;
//     } else if (slideObj.slide == 'reset') {
//       return state[slideObj.type] = 1;
//     } else {
//       return state[slideObj.type] = slideObj.slide;
//     }
//   }
// }