export default (context, inject) => {
    let seo = { 
        'home': {
            'title' : 'Home',
            'description' : 'Van tiener tot milf. Meiden van Holland is de sexzender voor geile amateur sexfilms en webcamsex met Hollandse meiden.',
            'keywords' : 'Meiden van Holland, sex, Hollandse sex, amateur sex, poldersex, Nederlandse sex',
        },
        'direct': {
            'title' : 'Direct',
            'slug' : 'direct',
            'description' : 'Kijk goedkoop en onbeberkt sexfilms met Meiden van Holland Direct. ACTIE: 1e maand = €1 voor 100% Geile amateursex.',
            'keywords' : 'direct, abonnement',
        },
        'sexfilms': {
            'title' : 'Sexfilms',
            'slug' : 'sexfilms',
            'description' : 'Bekijk geile sexfilms met Kim Holland en haar Hollandse meiden. Elke dag nieuwe sex en wekelijks een kersverse gratis sexfilm. ',
            'keywords' : 'sexfilms, Meiden van Holland sexfilms, sexfilm, sex films, gratis sexfilms, neukfilm',
        },
        'categorieen': {
            'title' : 'Genres',
            'slug' : 'genres',
            'description' : 'Sex in allerlei geile smaken zie je op Meiden van Holland. Met onze sexfilm genres worden alle geile fantasieën vervuld.',
            'keywords' : 'Meiden van Holland genres ',
        },
        'shows': {
            'title' : "Series",
            'slug' : 'series',
            'description' : "Bekijk gratis sex series. Van klassiekers uit de doos met Don en Ad tot aftrekinstructies van je favoriete modellen.",
            'keywords' : 'gratis sex, Don en Ad, Handyman',
        },
        'epg': {
            'title' : "TV-gids",
            'slug' : 'tv-gids',
            'description' : "Nu te zien op televisie bij Meiden van Holland Hard",
            'keywords' : 'tvgids, epg, tv, televisie',
        },
        'modellen': {
            'title' : 'Modellen',
            'slug' : 'modellen',
            'description' : 'Geile Hollandse amateur meiden en pornosterren spelen in de lekkerste sexfilms. Kim Holland en haar vriendinnen neuken voor jou in de lekkerste standjes',
            'keywords' : 'geile meiden, sex actrices, pornosterren, Hollandse meiden',
        },
        'shop': {
            'title' : 'Shop',
            'slug' : 'shop',
            'description' : 'Meiden van Holland bestaat 10 jaar! Koop nu één van onze jubileumpaketten',
            'keywords' : 'shop, webshop, jubileum',
        },
        'webcamsex': {
            'title' : 'Webcamsex',
            'slug' : 'webcamsex',
            'description' : 'Geile webcamsex met de geilste Hollandse meiden achter de cam. Live met echte Meiden van Holland en Amateurs.',
            'keywords' : 'webcamsex, webcam sex, geile webcam, cambabes',
        },
        'sexdating': {
            'title' : 'Sexdating',
            'slug' : 'https://www.jouwgeheimemilf.nl/landers/48A4DB65DF3EFCDE4536812FA4D4579949E47414F6D886D039342EDD7DD02F5B?p=323068&prid=1847647#1&pi=Dating_Topmenu_JGM_mvh',
            'slugv_2' : 'https://www.jouwgeheimemilf.nl/landers/48A4DB65DF3EFCDE4536812FA4D4579949E47414F6D886D039342EDD7DD02F5B?p=323068&prid=1847647#1&pi=Dating_Topmenu_JGM_mvh',
            'description' : 'Lekker daten met echte vrouwen voor echte sex.',
            'keywords' : 'Sexdating',
        }
    }
    inject('seo', seo)
}