export const strict = false;

export const state = () => ({
  loading: false
})

export const actions = {
  patchUser({commit}, data) {
    var loadingObj = {
      content: 'user',
      type: 'patch'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var self = this;
    this.$axios.patch(process.env.API_URL + '/users', data)
    .then(response => { 
      self.$auth.fetchUser();
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },   
  convertCredits({commit}) {
    commit('setLoading', true);
    this.$axios.post(process.env.API_URL + '/users/convert-credits?frontend_id=1')
    .then(response => { 
      this.$auth.fetchUser();
      commit('setLoading', false);
      var goto = window.location.href.replace('/mijn-credits','/gehuurd?credits=succes');
      window.location = goto;
    }).catch((error) => {
      console.log(error);
      commit('setLoading', false);      
    });
  },
  removeUser({commit}, user_id) {
    var loadingObj = {
      content: 'user',
      type: 'remove'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var self = this;
    this.$axios.delete(process.env.API_URL + '/users/remove')
    .then(response => { 
      self.$auth.logout();
      self.updateUserLoginStatus();
      self.$router.push({
        path: '/'
      })
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.href = '/info/contact';
      alertObj.message = 'Er is iets misgegaan. Klik op deze melding om contact op te nemen.';
      commit('alertStore/setAlert', alertObj, { root: true });    
    });
  },   
  toggleMail({commit}, data) {
    var loadingObj = {
      content: 'user',
      type: 'segment'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var self = this;
    this.$axios.post(process.env.API_URL + '/users/subscribe', data)
    .then(response => { 
      self.$auth.fetchUser();
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });

    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },   
  toggleFavorite({commit}, data) {
    var loadingObj = {
      content: 'user',
      type: 'favorite'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var self = this;
    if (data.subscribe === 1) {
      this.$axios.post(process.env.API_URL + '/favorite-' + data.url, data)
      .then(response => { 
        self.$auth.fetchUser();
        loadingObj.type = 'done';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });

      }).catch((error) => {
        console.log(error);
        loadingObj.type = 'error';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
      });
    } else {
      this.$axios.delete(process.env.API_URL + '/favorite-' + data.url + '/' + data.resource_id, data)
      .then(response => { 
        self.$auth.fetchUser();
        loadingObj.type = 'done';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });

      }).catch((error) => {
        console.log(error);
        loadingObj.type = 'error';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
      });
    }
  },
  toggleFavoMail({commit}, data) {
    var loadingObj = {
      content: 'user',
      type: 'favorite'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var self = this;
    this.$axios.post(process.env.API_URL + '/favorite-' + data.url + '/' + data.resource_id + '/mailing', data.todo)
    .then(response => { 
      self.$auth.fetchUser();
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });

    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },
  cancelIncasso({commit}) {
    var self = this;
    this.$axios.get(this.$config.apiUrl + '/user/incasso/cancel', {
    }).then(function (response) {
      self.$auth.fetchUser();
      var alertObj = {};
      alertObj.type = 'info';
      alertObj.message = 'Meiden van Holland Direct is opgezegd. Je kan nog tot de einddatum van onze Direct sexfilms genieten. Er zal geen incasso meer plaatsvinden.';
      commit('alertStore/setAlert', alertObj, { root: true });    
      commit('modalStore/setModal', false, { root: true })
    })
  },  
  reactivateIncasso({commit}) {
    var self = this;
    this.$axios.get(this.$config.apiUrl + '/user/incasso/reactivate', {
    }).then(function (response) {
      self.$auth.fetchUser();
      var alertObj = {};
      alertObj.type = 'info';
      alertObj.message = 'Meiden van Holland Direct is weer actief. Fijn dat je bij ons blijft!';
      commit('alertStore/setAlert', alertObj, { root: true });    
      commit('modalStore/setModal', false, { root: true })
    })
  },   
}

export const mutations = {
  setLoading(state, todo) {
    state.loading = todo;
  },
}