  export const strict = false;

  export const state = () => ({
    alert: {
      message: false,
      type: false,
      active: false
    }
  })

  export const getters = {
    alert(state) {
      return state.alert;
    }
  }

  export const mutations = {
    setAlert(state, alertObj) {
      alertObj.active = true;
      state.alert = alertObj;
    },
    clearAlert(state) {
      state.alert.active = false;
    }
  }