import { HmacSHA256 } from 'crypto-js'
import { getUnixTime } from 'date-fns';

function getToken() {
  // Get the current time in UTC and convert it to a Unix timestamp
  const now = new Date();
  const time = getUnixTime(now.getTime());
  return  'Syserauth ' + process.env.CLIENT_ID +'-'+ HmacSHA256(process.env.CLIENT_ID+time.toString(), process.env.CLIENT_SECRET).toString() + '-' + time.toString(16);
}

export default function ({ $axios }) {

  $axios.onRequest(config => {
    // config.headers.common['checkdomain-header'] = 'https://secretcircle.com';
    // config.headers.common['credentials'] = getToken();
  })
  if (process.server || process.client) {
    $axios.setHeader('checkdomain-header', process.env.FE_URL)
    $axios.setHeader('credentials', getToken())
  }


  $axios.onError(error => {
    return Promise.reject(error);
  })

  let loadExceptions = ['/api/sexfilms/update-stream', '/api/user/has-stream'];

  $axios.interceptors.request.use(function (config) {
    if(!loadExceptions.includes(config.url)){

    }
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
$axios.interceptors.response.use(function (response) {
  if(!loadExceptions.includes(response.data.url)){

  }
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });
}

