//
//
//

 export default {
   props: ['error'],
   components: {
     breadcrumbs: () => import('~/components/breadcrumbs')
   },
   mounted: function () {
    const error = new Error(this.error.message); 
    error.statusCode = this.error.statusCode;
    this.$sentry.captureException(error); 
  },
}
