//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  components: {
    Navigation: () => import('~/components/Navigation.vue'),
    searchbar: () => import('./searchbar'),
    info: () => import('./info'),
    notifications: () => import('./notifications'),
    creditsTimer: () => import('~/components/content/video/creditstimer.vue'),
    login: () => import('./userLogin')
  },
  props: ['scrollInnit'],
  watch: {
    '$route.path': function () {
      this.$store.commit('dropdownStore/setDropdown', false);
    },
  },
  computed: {
    ...mapState({
      dropdown: ({ dropdownStore: { dropdown } }) => dropdown,
      notifications: ({ notificationStore: { notifications } }) =>
        notifications,
      newNotifications: ({ notificationStore: { newNotifications } }) =>
        newNotifications,
      modal: ({ modalStore: { modal } }) => modal,
    }),
  },
  data() {
    return {
      activeLoginTab: 'login',
    };
  },
  methods: {
    activateDropdown(type) {
      if (type === this.dropdown) {
        this.$store.commit('dropdownStore/setDropdown', false);
      } else {
        this.$store.commit('dropdownStore/setDropdown', type);
      }
    },
    logintab: function (tab) {
      this.activeLoginTab = tab;
    },
  },
};
