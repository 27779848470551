export const state = () => ({
  isMobile: false,
  ieEdge: false,
  isSmallDevice: false,
});

export const mutations = {
  setIsMobile(state, isMobile) {
    state.isMobile = isMobile;
  },

  setIsIeEdge(state, ieEdge) {
    state.ieEdge = ieEdge;
  },

  setIsSmallDevice(state, isSmallDevice) {
    state.isSmallDevice = isSmallDevice;
  },
};
