import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
import { differenceInDays } from 'date-fns'

export const state = () => ({
  collection: [],
  resource: [],

  filterPerformerModels: [],

  query: {
    loadType: 'collection',
    include: 'images:types(thumb):limit(1|0),products',
    page: 1,
    count: 12,
    sort: {
      random: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },

  filter: {
    active: false,
    models: 'Modellen',
    sort: 'Shuffle',
    activated: {
      length: false,
      duration: false,
      models: false,
      shuffle: false,
    }
  },

  modelsQuery: {
    loadType: 'models',
    include: 'images:types(square):limit(1|0),products,categories',
    page: 1,
    count: 16,
    sort: {
      recommended_at: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },

  loading: false,
  pagination: false
})

export const actions = {
  async get({commit, rootState}, query) {
    if (query.page == 1) {
      var loadingObj = {
        content: 'collection',
        type: 'new'
      };
    } else {
      var loadingObj = {
        content: 'collection',
        type: 'map'
      };
    }
    commit('setLoading', loadingObj);
    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);    

    await this.$axios.get(process.env.API_URL + '/others?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&frontend_id=1' + '&type=private' + '&quick_notation=true')
    .then(response => { 
      response.data.type = query.loadType;
      if (rootState.auth.loggedIn && rootState.auth.user.rent.privates) {
        response.data.access = rootState.auth.user.rent.privates
      }
      var paginationObj = {
        content: 'collection',
        pagination: response.data.meta.pagination
      };
      commit('setPagination', paginationObj);
      commit('setCollection', response.data);        
      loadingObj.type = 'done';
      commit('setLoading', loadingObj);
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('setLoading', loadingObj);
      throw error;
    });
  },   

  async getResource({commit}, query) {
    var loadingObj = {
      content: 'resource',
      type: 'new'
    };
    commit('setLoading', loadingObj);

    let filters = serialize.serializeFilter(query.filter);    
    let user_id = '';
    if (query.user_id) {
      user_id = '&user_id=' + query.user_id;    
    }
    await this.$axios.get(process.env.API_URL + '/others/' + query.slug + '?include=' + query.include + filters + '&type=private&quick_notation=true' + user_id)
    .then(response => {
      commit('setResource', response.data);
      loadingObj.type = 'done'
      commit('setLoading', loadingObj);    
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('setLoading', loadingObj);
      throw error;
    });
  },
  async getFilterPerformerModels({commit}, query) {
    await this.$axios.get(process.env.API_URL + '/performermodels-list')
    .then(response => { 
      return commit('setFilterPerformerModels', response.data);        
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('setLoading', loadingObj);
      throw error;
    });
  },   
}

export const getters = {
  collection(state) {
    return state.collection;
  },
  resource(state) {
    return state.resource;
  },
  loading(state) {
    return state.loading;
  },
  pagination(state) {
    return state.pagination;
  },
  query(state) {
    return state.query;
  },
  filterPerformerModels(state) {
    return state.filterPerformerModels;
  },
  modelsQuery(state) {
    return state.modelsQuery;
  }
}

export const mutations = {
  setCollection(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var collection = deserialize(dataObj);    

    var access = data.access;

    const now = new Date();
    collection.forEach(function(resource) {
      resource.images.forEach(function(element) {  
        if (element.types == 'thumb') {
          resource.thumb = element.sizes;
          resource.thumb.original = element.path;
        }
        if (element.types == 'thumb_mobile') {
          resource.thumb_mobile = element.sizes;
          resource.thumb_mobile.original = element.path;
        }
      });
      if (resource.videos && resource.videos.free_film && resource.videos.free_film[0].duration) {
        resource.video_free = resource.videos.free_film[0];
        resource.video_free.duration = resource.video_free.duration.substring(3);
      }
      if (resource.videos && resource.videos.film && resource.videos.film[0].duration) {
        resource.video_paid = resource.videos.film[0];
        resource.video_paid.duration = resource.video_paid.duration.substring(3);
      }
      if (resource.videos && resource.videos.trailer) {
        resource.trailer = resource.videos.trailer[0];
      }
      
      const days = Math.abs(differenceInDays(now, new Date(resource.frontend.recommended_at)));
      if (days == 0) {
        resource.active_from = 'vandaag';
      } else if (days == 1) {
        resource.active_from = '1 dag geleden';
      } else {
        resource.active_from = days + ' dagen geleden';
      }
      if (access) {
        access.forEach(function(item) {
          if (resource.id == item.resource_id) {
            resource.access = item;
          }
        })
      }
      if (resource.fields && resource.fields.price) {
        var euro = resource.fields.price / 100;
        var price = euro.toFixed(2);
        resource.fields.price = price
      }
    })

    collection.pagination = data.meta.pagination;

    if (data.meta.pagination.current_page == 1) {
      state[data.type] = collection;
    } else {
      state[data.type] = state[data.type].concat(collection);
    }
  },
  setResource(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var resource = deserialize(dataObj);

    if (resource.access && resource.access.included) {
      var accessDataObj = [];
      accessDataObj.included = resource.access.data.included;
      accessDataObj.data = resource.access.data;
      var access = deserialize(accessDataObj);      
      resource.access = [];
      access.forEach(function(element) { 
        resource.access.push(element); 
      });
    }

    if (resource.videos && resource.videos.free_film) {
      resource.video_free = resource.videos.free_film[0];
      resource.video_free.duration = resource.video_free.duration.substring(3);
    }
    if (resource.videos && resource.videos.film) {
      resource.video_paid = resource.videos.film[0];
      resource.video_paid.duration = resource.video_paid.duration.substring(3);
    }

    const now = new Date();
    const days = Math.abs(differenceInDays(now, new Date(resource.frontend.recommended_at)));

    if (days == 0) {
      resource.active_from = 'vandaag';
    } else if (days == 1) {
      resource.active_from = '1 dag geleden';
    } else {
      resource.active_from = days + ' dagen geleden';
    }
    
    if (resource.fields && resource.fields.price) {
      var euro = resource.fields.price / 100;
      var price = euro.toFixed(2);
      resource.fields.price = price
    }

    state.resource = resource;
  },

  setLoading(state, loadingObj) {
    state.loading = loadingObj.type;
  },
  setPagination(state, paginationObj) {
    state.pagination = paginationObj.pagination;
  },

  setFilterPerformerModels(state, data) {
    state.filterPerformerModels = data;    
  },

  updateQuery(state, query) {
    if (query.type == 'sort') {
      state.query.sort = false;
      state.query.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.query.page = query.name;
      if (query.name > 1) {
        state.query.type = 'map';
      }
    } else if(query.type == 'page') {
      state.query.page = query.name;
    } else if(!query.name && !query.id) {
      state.query.filters = {
        status: 'published',
        products: '1,2',
        types: 'private'
      }
    } else {
      state.query.filters[query.type] = query.id;
    }
  },

  updateFilterState(state, filterState) {
    state.filter[filterState.type] = filterState.name;
    if(filterState.name != 'Modellen' && filterState.name != 'Nieuwste eerst') { 
      state.filter.activated[filterState.type] = filterState.name;
    } else {
      state.filter.activated[filterState.type] = false
    }
    state.filter.active = true;
    if (state.filter.models == 'Modellen' && state.filter.language == 'Nieuwste eerst') {
      state.filter.active = false;
    }
  },
}