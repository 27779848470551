import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
export const strict = false;

export const state = () => ({
  spotlight: [],
})

export const actions = {
  async getSpotlight({commit}, query) {
    var loadingObj = {
      content: 'spotlight',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);    
    await this.$axios.get(process.env.API_URL + '/others?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&type=spotlight' + '&frontend_id=1')
    .then(response => { 
      commit('setSpotlight', response.data);        
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      this.app.$sentry.captureException(error);
    });
  },
}

export const getters = {
  spotlight(state) {
    return state.spotlight;
  },
}

export const mutations = {
  setSpotlight(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var spotlight = deserialize(dataObj);    

    state.spotlight = spotlight[0];
  }
}