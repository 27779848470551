import { deserialize } from 'jsonapi-deserializer';

export const strict = false;

export const state = () => ({
  pages: {
    home: false,
    direct: false,
    sexfilms: false,
    freesexfilms: false,
    categorieen: false,
    modellen: false,
    programmas: false,
    webcamsex: false,
    gids: false,
    over: false,
    aanmelden: false,
    faq: false,
    terms: false,
    feedback: false,
    nieuwsbrief: false,
    privacy: false,
    contact: false,
    sexlink: false,
    nieuws: false,
    privefilmpjes: false,
    provider: false,
    energie: false,
    betaald: false,
    inmeikiesjij: false
  },
  sitelinks: []
})

export const actions = {
  async getPage({commit}, query) {
    let name = 'mvh-' + query.name;
    await this.$axios.get(process.env.API_URL + '/pages/' + name + '?include=seo')
    .then(response => { 
      response.data.name = query.name;
      commit('setPage', response.data);        
    }).catch((error) => {
      console.log(error);
    });
  },
  async getSitemap({commit}, page) {
    await this.$axios.get(process.env.API_URL + '/sitemap' + '?frontend_id=1')
    .then(response => { 
      response.page = page;
      commit('setLinks', response);        
    }).catch((error) => {
      console.log(error);
    });
  },
}

export const getters = {
  pages(state) {
    return state.pages;
  },
  sitelinks(state) {
    return state.sitelinks;
  },
}

export const mutations = {
  setPage(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var page = deserialize(dataObj);
    
    state.pages[data.name] = page;
  },
  setLinks(state, data) {    
    var resp = paginate(data.data.data, 100, data.page);
    resp.forEach(function(element) {
      state.sitelinks.push(element);
    })
  },
}

const paginate = function(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
