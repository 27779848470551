import { deserialize } from 'jsonapi-deserializer';
export const state = () => ({
    banner: {
        Home: {},
        HomeDirect: {},
        collection: {},
        sexfilmsCollection: {},
        genresXl: {},
        modellenXl: {},
        webcamCollection: {}
    },
})
export const actions = {
    async getBanner({commit}, query) {
      var loadingObj = {
        content: 'banner',
        type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    await this.$axios.get(process.env.API_URL + '/banners?locations=' + query.zone + '&include=' + query.include + '&frontend_id=1')
    .then(response => {
        response.data.location = query.zone;
        commit('setBanner', response.data);
        loadingObj.type = 'done';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
  });
}
}

export const mutations = {
    setBanner(state, data) {
        var dataObj = [];
        dataObj.included = data.included;
        dataObj.data = data.data;
        
        var banners = deserialize(dataObj);

        var random = Math.floor(Math.random() * banners.length);
        var banner = banners[random];
        banner.location = data.location; 
        banner.images.forEach(function(element) {  
            if (element.type == 'Desktop') {
              banner.desktop = element.path;
          }
          if (element.type == 'Mobile') {
              banner.mobile = element.path;
          }
      });
        if (!state.banner[banner.location]) {
            state.banner[banner.location] = [];
        }
        state.banner[banner.location] = banner;
    },
}