import { serializeCam } from 'assets/js/serialize.js';

export const strict = false;

export const state = () => ({
  cams: [],
  cam: [],
  filterWebcamModels: [],
  cam_highlight_mvh: false,


  camsQuery: {
    frontend_id: 1,
    include: 'images:types(cover):limit(1|0),products,categories',
    sort: {
      published_at: 'DESC'
    },
    filters: {
      geslacht: 'v',
      leeftijd: '',
      uiterlijk: '',
      haar: '',
      figuur: '',
      taal: 'NL',
      orderby: 'waarderingdesc',
      page: 1,
      limit: 30,
    }
  },
  camFilter: {
    active: true,
    orderby: 'Sorteren',
    geslacht: 'Vrouw',
    taal: 'Nederlands',
    leeftijd: 'Leeftijd',
    uiterlijk: 'Uiterlijk',
    haar: 'Haarkleur',
    figuur: 'Figuur',
    activated: {
      geslacht: 'Vrouw',
      taal: 'Nederlands',
      leeftijd: false,
      uiterlijk: false,
      haar: false,
      figuur: false,
    }
  }
})

export const actions = {
  async getCams({commit}, query) {
    if (query.filters.page == 1) {
      var loadingObj = {
        content: 'cams',
        type: 'new'
      };
    } else {
      var loadingObj = {
        content: 'cams',
        type: 'map'
      };
    }
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    var serFilter = serializeCam.serializeCam(query.filters);
    var checkTaal = serFilter.includes("NL");
    await this.$axios.get(process.env.API_URL + '/webcams?' + serFilter + '&page=' + query.filters.page)
    .then(response => {
      if (response.data.data.length < 1 && checkTaal || !response.data.data.length && checkTaal) {
        var res = serFilter.replace("NL", "");
        this.$axios.get(process.env.API_URL + '/webcams?' + res)
        .then(response => { 
          if (response.data.data.length < 1 || !response.data.data.length) {
            this.$axios.get(process.env.API_URL + '/webcams?&where[online][in]=0,1&' + serFilter)
            .then(response => { 
              if (query.filters.page == 1) {
                loadingObj.type = 'done';
                commit('loadstateStore/setLoadState', loadingObj, { root: true });
                commit('setAllCams', response.data);        
              }else {
                loadingObj.type = 'done';
                commit('loadstateStore/setLoadState', loadingObj, { root: true });
                commit('mapAllCams', response.data);        
              }
            }).catch(error => {
              loadingObj.type = 'error';
              commit('loadstateStore/setLoadState', loadingObj, { root: true });
            })
          }
          if (query.filters.page == 1) {
            loadingObj.type = 'done';
            commit('loadstateStore/setLoadState', loadingObj, { root: true });
            commit('setAllCams', response.data);        
          }else {
            loadingObj.type = 'done';
            commit('loadstateStore/setLoadState', loadingObj, { root: true });
            commit('mapAllCams', response.data);        
          }
        }).catch(error => {
          loadingObj.type = 'error';
          commit('loadstateStore/setLoadState', loadingObj, { root: true });
        })
      } else {
        if (query.filters.page == 1) {
          loadingObj.type = 'done';
          commit('loadstateStore/setLoadState', loadingObj, { root: true });
          commit('setAllCams', response.data);   
        } else {
          loadingObj.type = 'done';
          commit('loadstateStore/setLoadState', loadingObj, { root: true });
          commit('mapAllCams', response.data);   
        }   
      }  
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  },

  async getCam({commit}, slug) {
    var loadingObj = {
      content: 'cams',
      type: 'resource'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    await this.$axios.get(process.env.API_URL + '/webcams/' + slug)
    .then(response => { 
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      commit('setCam', response.data);        
    }).catch((error) => {
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    });
  }, 

  async getFilterWebcamModels({commit}, query) {
    await this.$axios.get(process.env.API_URL + '/webcammodels-list')
    .then(response => { 
      return commit('updateCams', response.data);        
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('setLoading', loadingObj);
      throw error;
    });
  },   

}

export const getters = {
  cams(state) {
    return state.cams;
  },
  cam(state) {
    return state.cam;
  },
  camFilter(state) {
    return state.camFilter;
  }
}

export const mutations = {
  setAllCams(state, data) {
    if (data.data) {
      var cam = data.data;
      cam.forEach((item, i) => {
        item.thumb = 'https://images.islive.nl/snapshot/' + item.modelnaam + '/snapshot.jpg'
        item.id = i + 1;
        if (item.modelnaam == 'avajsade' || item.modelnaam == 'fruistigetieten') {
          item.mvh = true;
          state.cam_highlight_mvh = true;
        } else {
          item.mvh = false;
        }
      });
      state.cams = cam;
    } else {
      state.cams = false;
    }
  },
  updateCams(state, data) {
    data.forEach((item) => {
      var cams = [];
      state.cams.forEach((cam) => {
        if (cam.modelnaam == 'daphne-laat' || 
          cam.modelnaam == 'ashley-more' ||
          cam.modelnaam == 'kim_blaze' ||
          cam.modelnaam == 'dumore' ||
          cam.modelnaam == 'mandyslim' ||
          cam.modelnaam == 'curlygirl' ||
          cam.modelnaam == 'esluna-love') {
          cam.mvh = true;
      } else {
        cam.mvh = false;
      }
      cams.push(cam);
    })
      state.cams = cams;
    })
  },
  mapAllCams(state, data) {
    state.cams = state.cams.concat(data.data);
  },
  setCam(state, data) {
    var cam = data.data[0];

    cam.gallery = [];
    var i;
    for (i = 1; i < 4; i++) { 
     cam.gallery.push('https://images.islive.nl/snapshot/'+ cam.modelnaam + '/snapshot-0' + [i] + '.jpg');
   }
   state.cam = cam;
 },
 updateQuery(state, query) {
  if (query.type == 'orderby') {
    state.camsQuery.filters[query.type] = query.name;
  } else {
    if (!query.id) {
      delete state.camsQuery.filters[query.type];
    } else {
      state.camsQuery.filters[query.type] = query.id;
    }
  }
},

search(state, query) {
  if (query.type && query.input) {
    state.camsQuery.filters[query.type] = query.input;
  } else {
    state.camsQuery.filters.modelnaam = false;
  }
},     

setFilterWebcamModels(state, data) {
  state.filterWebcamModels = data;    
},

updateFilterState(state, filterState) {
  state.camFilter[filterState.type] = filterState.name;
  state.camFilter.activated[filterState.type] = filterState.name;
  if(filterState.name != 'Figuur' && filterState.name != 'Geslacht' && filterState.name != 'Haarkleur' && filterState.name != 'Leeftijd' && filterState.name != 'Sorteren' && filterState.name != 'Nederlands' && filterState.name != 'Uiterlijk') { 
    state.camFilter.activated[filterState.type] = filterState.name;
  } else {
    state.camFilter.activated[filterState.type] = false
  }
  state.camFilter.active = true;
  if (filterState.type != 'orderby' && filterState.type != 'search') {
    state.camFilter.allFilters = 'Filters: AAN'
  }
  if (state.camFilter.orderby == 'Sorteren' && state.camFilter.figuur == 'Figuur' && state.camFilter.geslacht == 'Geslacht' && state.camFilter.haar == 'Haarkleur' && state.camFilter.leeftijd == 'Leeftijd' && state.camFilter.orderby == 'Sorteren' && state.camFilter.taal == 'Taal' && state.camFilter.uiterlijk == 'Uiterlijk') {
    state.camFilter.active = false;
  }
},
}