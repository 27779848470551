var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",class:{ phablet: this.phablet }},[(!this.$root.cookieSet)?_c('cookie'):_vm._e(),_vm._v(" "),_c('Header',{class:{
    active: _vm.$root.header,
    home: _vm.$route.name == 'index',
    scroll: _vm.$root.scroll,
    playerActive:
    (_vm.playerState.status == 'playing' &&
      _vm.playerState.type != 'trailer' &&
      _vm.playerState.type != 'preroll' &&
      _vm.playerState.type != 'private') ||
    (_vm.playerState.status == 'pause' &&
      _vm.playerState.type != 'trailer' &&
      _vm.playerState.type != 'preroll' &&
      _vm.playerState.type != 'private'),
    notontop: _vm.$root.notontop,
    resource_item:
    (_vm.$route.name && _vm.$route.name.includes('slug')) ||
    !_vm.$route.name ||
    _vm.$route.name == 'index',
  }}),_vm._v(" "),_c('aside',{staticClass:"header_spacer",class:{
    resource_item:
    (_vm.$route.name && _vm.$route.name.includes('slug')) ||
    _vm.$route.name == 'index',
  }}),_vm._v(" "),_c('nuxt',{class:{
    scroll: this.$root.scroll,
    notontop: this.$root.notontop,
    alert: _vm.alertActive,
  }}),_vm._v(" "),_c('Footer',{class:{ active: this.$root.header }}),_vm._v(" "),_c('client-only',[_c('alertHandler',{class:{ internetexplorer: _vm.ieEdge }}),_vm._v(" "),_c('Navigation')],1),_vm._v(" "),_c('aside',{staticClass:"nav_spacer"}),_vm._v(" "),(_vm.modal == 'account')?_c('account'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }