export const strict = false;

export const state = () => ({
  analytics: {
    item_id:  '',
    item_name: '',
    currency: 'EUR',
    price: '',
    item_brand: '', // Sexfilm of private
    item_variant: '', // bundle_1 of bundle_2 etc.
    affiliation: '', // ZUN
    item_category: '', // direct of huren of ppm
    item_list_id: '', // recommended_videos
    item_list_name: '' // Aanbevolen videos
  },
})
export const actions = {   
  beginCheckout({commit, rootState, state}, subQuery){
    if (process.client && window.dataLayer) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'begin_checkout',
        value: rootState.subscriptionStore.accessitems.item.price_int / 100,
        items: [{
          item_id: state.analytics.item_id,
          item_name: state.analytics.item_name,
          currency: 'EUR',
          price: rootState.subscriptionStore.accessitems.item.price_int / 100,
          item_brand: state.analytics.item_brand,
        // affiliation: this.analytics.affiliation,
          item_variant: rootState.subscriptionStore.accessitems.item.slug,
          item_category: rootState.subscriptionStore.accessitems.item.type,
          item_list_id: state.analytics.item_list_id, 
          item_list_name: state.analytics.item_list_name
        }]
      });
    }
  },
  paymentInitiation({commit, rootState, state}, subQuery){
    if (process.client && window.dataLayer) {
     window.dataLayer.push({ ecommerce: null });
     window.dataLayer.push({
       event: 'begin_checkout',
       currency: 'EUR',
       value: rootState.subscriptionStore.accessitems.item.price_int / 100,
       payment_type: 'payment_method_id-' + rootState.subscriptionStore.accessitems.choice.payment_method_id,
       items: [{
        item_id: state.analytics.item_id,
        item_name: state.analytics.item_name,
        currency: 'EUR',
        price: rootState.subscriptionStore.accessitems.item.price_int / 100,
        item_brand: state.analytics.item_brand, 
        // affiliation: this.analytics.affiliation,
        item_variant: rootState.subscriptionStore.accessitems.item.slug,
        item_category: rootState.subscriptionStore.accessitems.item.type,
        item_list_id: state.analytics.item_list_id, 
        item_list_name: state.analytics.item_list_name
      }]
     });
   }
 },
}

export const mutations = {
  updateFullAnalyticsObject(state, data) {
   state.analytics = Object.assign({}, state.analytics, data);
 },
 updateCatPrice(state, data) {
  state.analytics.category = data.category;
  state.analytics.price = data.price;
}

}