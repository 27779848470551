  /*
  ** JS functions to load streams
  */
import Vue from 'vue'
import { mapState } from 'vuex'
let impressionList = [];
let isScrolled = false;
Vue.directive('inFocusHorizontal', {
  inserted: (el, binding, vnode) => {
    let f = () => {
      let rect = el.getBoundingClientRect()
      let inView = (
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
        )
      if (inView && !isScrolled) {
        isScrolled = true;
        viewItem(binding.value);        
      }
    }

    let container = el.parentElement;
    container.addEventListener('scroll', f);
    var isScrolled = false;
    setTimeout(function() {
      f()
    }, 500);
  }
})

Vue.directive('inFocusVertical', {
  inserted: (el, binding, vnode) => {
    let f = () => {
      let rect = el.getBoundingClientRect()
      let inView = (
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        )
      if (inView && !isScrolled) {
        isScrolled = true;
        viewItem(binding.value);        
      }
    }
    window.addEventListener('scroll', f);
    var isScrolled = false;
    setTimeout(function() {
      f()
    }, 500);
  }
})

Vue.directive('inFocusVerticalItemList', {
  inserted: (el, binding, vnode) => {
    let f = () => {
      let rect = el.getBoundingClientRect()
      let inView = (
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        )
      if (inView && !isScrolled) {
        isScrolled = true;
        viewItemList(binding.value);        
      }
    }
    window.addEventListener('scroll', f);
    var isScrolled = false;
    setTimeout(function() {
      f()
    }, 500);
  }
})

function viewItem(binding){
  if (process.client && window.dataLayer) {
   window.dataLayer.push({ ecommerce: null });
   window.dataLayer.push({
    event: 'view_item',
    items: [{
      item_id: binding[0].id,
      item_name: binding[0].slug,
      item_list_id: binding[3], 
      item_list_name: binding[4]
    }]
  });
 }
}

function viewItemList(binding) {
  // const items_in_list = [];
  // binding[0].forEach(entry => {
  //   items_in_list.push({
  //     item_id: entry.id,
  //     item_name: entry.slug,
  //   });
  // })
  if (process.client && window.dataLayer) {
   window.dataLayer.push({ ecommerce: null });
   window.dataLayer.push({
    event: 'view_item_list',
    item_list_id: binding[1],
    item_list_name: binding[2]
    // items: items_in_list
  });
 } 
}

// Usage in item: v-inFocusList="[itemDetails]"


Vue.mixin({
  computed: {
    ...mapState({
      analytics: ({ analyticsStore: {analytics} }) => analytics,
      accessitems: ({ subscriptionStore: {accessitems} }) => accessitems,
    }),
  },
  methods: {
    updateUserLoginStatus() {
      const isLoggedIn = this.$auth.loggedIn
      if (process.client && window.dataLayer) {
        window.dataLayer.push({
          event: 'userLoginStatus',
          userLoggedIn: isLoggedIn
        });
      }
    },
    trackButtonClick(trackevent, buttonName, videoTitle) {
      if (process.client && window.dataLayer) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'clearDataLayer'});
        window.dataLayer.push({
          event: trackevent,
          videoTitle: videoTitle,
          buttonName: buttonName
        });
      } 
    },
   //  viewItemList(item_list_id, item_list_name, content) {
   //    const items_in_list = [];
   //    content.forEach(entry => {
   //      items_in_list.push({
   //        item_id: entry.id,
   //        item_name: entry.slug,
   //      });
   //    })
   //    if (process.client && window.dataLayer) {
   //     window.dataLayer.push({ ecommerce: null });
   //     window.dataLayer.push({
   //      event: 'view_item_list',
   //      item_list_id: item_list_id,
   //      item_list_name: item_list_name,
   //      items: items_in_list
   //    });
   //   } 
   // },
    selectItem(){
      if (process.client && window.dataLayer) {
       window.dataLayer.push({ ecommerce: null });
       window.dataLayer.push({
        event: 'select_item',
        item_list_id: this.analytics.item_list_id, 
        item_list_name: this.analytics.item_list_name,
        items: [{
          item_id: this.analytics.item_id,
          item_name: this.analytics.item_name,
      // currency: 'EUR',
      // price: this.analytics.price,
          item_brand: this.analytics.item_brand, 
      // affiliation: this.analytics.affiliation,
          item_category: this.analytics.item_category,
          item_list_id: this.analytics.item_list_id, 
          item_list_name: this.analytics.item_list_name
        }]
      });
     }
   },

   addToCart(){
    if (process.client && window.dataLayer) {
     window.dataLayer.push({ ecommerce: null });
     window.dataLayer.push({
      event: 'add_to_cart',
      item_list_id: this.analytics.item_list_id, 
      item_list_name: this.analytics.item_list_name,
      items: [{
        item_id: this.analytics.item_id,
        item_name: this.analytics.item_name,
      // currency: 'EUR',
      // price: this.analytics.price,
        item_brand: this.analytics.item_brand, 
      // affiliation: this.analytics.affiliation,
        item_category: this.analytics.item_category,
        item_list_id: this.analytics.item_list_id, 
        item_list_name: this.analytics.item_list_name
      }]
    });
   }
 },

 updateCart(type){
  if (process.client && window.dataLayer) {
   window.dataLayer.push({ ecommerce: null });
   window.dataLayer.push({
    event: 'update_cart_item',
    value: this.analytics.price,
    items: [{
      item_id: this.analytics.item_id,
      item_name: this.analytics.item_name,
      currency: 'EUR',
      price: this.analytics.price,
      item_brand: 'sexfilm', 
      affiliation: this.analytics.affiliation,
      item_variant: this.analytics.item_variant,
      item_category: this.analytics.item_category,
      item_list_id: this.analytics.item_list_id, 
      item_list_name: this.analytics.item_list_name
    }]
  });
 }
},
accessTypeSelection(accessType){
  if (process.client && window.dataLayer) {
    window.dataLayer.push({
      event: 'accessTypeSelection',
      accessType: accessType.slug
    });
  }
},
packageSelection(accessType, accessPackage){
  if (process.client && window.dataLayer) {
    window.dataLayer.push({
      event: 'packageSelection',
      accessType: accessType.slug,
      package: accessPackage.slug, 
      amount: accessPackage.price_int / 100
    });
  }
},
paymentCompletion(accessType, video, orderId){
  accessType.slug = 'huren'
  if (accessType.short === 'abo') {
    accessType.slug = 'direct'
  }
      // This will all change in New API and Nuxt3
  accessType.price = 699;
  if (accessType.sub_detail_id === 2 || accessType.sub_detail_id === 39) {
    accessType.price = 499
  }
  if (accessType.sub_detail_id === 16) {
    accessType.price = 1499
  }
  if (accessType.sub_detail_id === 17) {
    accessType.price = 2999
  }
  if (accessType.sub_detail_id === 18) {
    accessType.price = 4999
  }
  if (accessType.sub_detail_id === 19) {
    accessType.price = 8999
  }
  if (accessType.sub_detail_id === 20) {
    accessType.price = 1750
  }
  if (accessType.sub_detail_id === 45) {
    accessType.price = 499
  }
  if (accessType.sub_detail_id === 46) {
    accessType.price = 1999
  }
  if (accessType.sub_detail_id === 47) {
    accessType.price = 3999
  }
  if (accessType.sub_detail_id === 48) {
    accessType.price = 6999
  }
  if (process.client && window.dataLayer) {
   window.dataLayer.push({ ecommerce: null });
   window.dataLayer.push({
    event: 'purchase',
    currency: 'eur',
    transaction_id: orderId,
    value: accessType.price  / 100,
    items: [{
      item_id: video.id,
      item_name: video.slug
    }]
  });
 }
},
videoAccess(accessType, video){
  accessType.slug = 'huren'
  if (accessType.short === 'abo') {
    accessType.slug = 'direct'
  }
  if (process.client && window.dataLayer) {
    window.dataLayer.push({
      event: 'videoAccess',
      accessType: accessType.slug, 
      videoTitle: video.slug
    });
  }
},
}
})