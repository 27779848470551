//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data() {
    return {
      mounted: false,
      answer: false
    }
  },
  mounted: function() {
    if(this.$root.cookieSet) {
      this.switchScroll('on');
    } else {
      this.switchScroll('off');
    }
    this.mounted = true;
  },
  methods: {
    setAnswer: function(answer) {
      if (answer == 'ja') {
        this.answer = answer;
        this.setCookie();
      } else {
        this.answer = answer;
      }
    },
    setCookie: function() {
      let cname = 'agecookies';
      let cvalue = true;
      let exdays = 365;
      this.storeCookie(cname, cvalue, exdays);
      this.$root.cookieSet = true;
      this.switchScroll('on');
    },
    gotoPrivacy: function() {
      this.switchScroll('on');
    },
  }
}
