import { format } from 'date-fns'

export function formatDate(value) {
  if (value) {
    return format(new Date(value), 'dd/MM/yy');
  }
}

export function formatRentDate(value) {
  if (value) {
    return format(new Date(value), 'dd/MM/yy - kk:mm');
  }
}

export function slugToTitle(value) {
  if (value) {
    var str = value.replace(/-/g, ' ');
    var str2 = str.replace(/\\|\//g,'');
    return str2.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export function escapeHtmlEntities(value) {
  if (value) {
    return value.replace(/&/g, '&amp;').replace(/\+/g, '%2B').replace(/\s/g, '+');
  }
}
