export const strict = false;

export const state = () => ({
  almostBottom: false,
})

export const mutations = {
  setAlmostBottom(state, todo) {
    state.almostBottom = todo;
  }
}