// file needed for supporting old version of browsers 
export default (context, inject) => {
  const supportsWebP = () => {
    if (!process.client) return true
    const elem = document.createElement('canvas');
    return !!(elem.getContext && elem.toDataURL('image/webp').indexOf('data:image/webp') === 0);
  };

  const imgPreset = { ...context.$img.options.presets };

  // Function to conditionally update the format property and move other properties to the top level
  const updateFormat = (preset) => {
    if (preset.modifiers) {
      for (const prop in preset.modifiers) {
        if (prop === 'format' && preset.modifiers.format === 'webp') {
          preset.format = supportsWebP() ? 'webp' : '';
        } else {
          preset[prop] = preset.modifiers[prop];
        }
      }
      delete preset.modifiers;
    }
  };

  // Loop through the imgPreset and update the format property and other properties
  for (const key in imgPreset) {
    updateFormat(imgPreset[key]);
  }

  inject("imgPreset", imgPreset);
};
