import Vue from 'vue';
import { mapState } from 'vuex';

Vue.mixin({
  mounted() {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
  },
  computed: {
    ...mapState({
      isMobile: ({ deviceStore: { isMobile } }) => isMobile,
      ieEdge: ({ deviceStore: { ieEdge } }) => ieEdge,
      smallDevice: ({ deviceStore: { isSmallDevice } }) => isSmallDevice,
    }),
    directActive() {
      return (
        this.$auth.loggedIn &&
        this.$auth.user.recurring &&
        this.$auth.user.recurring[0]
      );
    },
  },
});
