import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
import { format, formatISODuration } from 'date-fns';

export const strict = false;

export const state = () => ({
  videos: [],

  votw: [],
  recommendedVideos: [],
  topVideos: [],
  newestVideos: [],
  newrecurring: [],
  plannedVideos: [],
  freevideos: [],
  exclusiveVideos: [],

  categoryVideos: [],
  categoryVideosFirst: [],
  categoryVideosSecond: [],
  categoryVideosThird: [],
  categoryVideosFourth: [],

  showVideos: [],
  modelVideos: [],
  randomVideos: [],
  votw_free: [],
  votw_new: [],

  video: [],
  related: [],

  videosQuery: {
    type: 'new',
    loadType: 'videos',
    include: 'images:types(thumb|thumb_mobile),products,categories,clips',
    page: 1,
    count: 20,
    api_type: 'video',
    sort: {
      recommended_at: 'DESC'
    },
    filter: {
      status: 'published',
      products: '1,2',
    }
  },

  freevideosQuery: {
    type: 'new',
    loadType: 'freevideos',
    include: 'images:types(thumb):limit(1|0),products,categories,clips',
    page: 1,
    count: 20,
    api_type: 'video',
    sort: {
      recommended_at: 'DESC'
    },
    filter: {
      status: 'published',
      products: '1',
      types: 'video'
    }
  },

  highlightQuery: {
    type: 'highlight-video',
    loadType: 'highlight',
    include: 'images,products,categories',
    page: 1,
    filter: {
      status: 'published',
      products: '2',
    }
  },

  categoryVideosQuery: {
    type: 'new',
    loadType: 'categoryVideos',
    api_type: 'video',
    include: 'images:types(thumb):limit(1|0),products,categories,clips',
    page: 1,
    count: 20,
    sort: {
      recommended_at: 'DESC'
    },
    filter: {
      status: 'published',
      products: '1,2',
      types: 'video'
    }
  },

  showVideosQuery: {
    type: 'new',
    loadType: 'showVideos',
    api_type: 'video',
    include: 'images:types(thumb):limit(1|0),products,categories,clips',
    page: 1,
    count: 20,
    sort: {
      recommended_at: 'ASC'
    },
    filter: {
      status: 'published',
      products: '1,2',
      types: 'video',
      planned: 1
    }
  },

  modelVideosQuery: {
    type: 'new',
    loadType: 'modelVideos',
    api_type: 'video',
    include: 'images:types(thumb):limit(1|0),products,categories,clips',
    page: 1,
    count: 20,
    sort: {
      recommended_at: 'DESC'
    },
    filter: {
      status: 'published',
      products: '1,2',
      types: 'video'
    }
  },

  filter: {
    active: false,
    products: 'Type',
    language: 'Taal',
    sort: 'Aanbevolen',
    models: 'Modellen',
    categories: 'Genres',
    activated: {
      products: false,
      language: false,
      models: false,
      categories: false,
      sort: false,
    }
  },
  
  freesort: 'Aanbevolen'
})

export const actions = {
  async getVOTW({commit}, query) {
    var loadingObj = {
      content: 'votw',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });

    let filters = serialize.serializeFilter(query.filter);    
    await this.$axios.get(process.env.API_URL + '/video-of-week?include=' + query.include + filters + '&type=video&frontend=' + process.env.FRONTEND_ID)
    .then(response => {
      commit('setVOTW', response.data);
      loadingObj.type = 'done'
      commit('loadstateStore/setLoadState', loadingObj, { root: true });    
    }).catch((error) => {
      if (error) {
        loadingObj.type = 'error';
        commit('loadstateStore/setLoadState', loadingObj, { root: true });
        throw error;
      }
    });
  },

  async getVideos({commit}, query) {
    var loadingObj = {
      content: query.loadType,
      type: query.type
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filter);    
    await this.$axios.get(process.env.API_URL + '/videos?page=' + query.page + '&count=' + query.count + '&type=' + query.api_type + '&include=' + query.include + filters + sort + '&frontend=' + process.env.FRONTEND_ID)
    .then(response => { 
      if (query.loadType) {
        response.data.type = query.loadType;
      } else {
        response.data.type = 'videos';
      }
      var paginationObj = {
        content: 'videos',
        pagination: response.data.meta.pagination
      };
      commit('loadstateStore/setPagination', paginationObj, { root: true });
      commit('setAllVideos', response.data);        
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });  
  },   

  async getVideo({commit}, query) {
    var loadingObj = {
      content: 'video',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    let filters = serialize.serializeFilter(query.filter);
    let user_id = '';
    var url = process.env.API_URL + '/cached-videos/' + query.slug + '?include=' + query.include + filters + user_id + '&type=video&frontend=' + process.env.FRONTEND_ID;
    if (query.user_id) {
      user_id = '&user_id=' + query.user_id;
      url = process.env.API_URL + '/videos/' + query.slug + '?include=' + query.include + filters + user_id + '&type=video&frontend=' + process.env.FRONTEND_ID
    }
    if (query.phone) {
      url = process.env.API_URL + '/videos/' + query.slug + '?include=' + query.include + filters + user_id + '&type=video&frontend=' + process.env.FRONTEND_ID
    }
    await this.$axios.get(url)
    .then(response => {
      commit('setVideo', response.data);
      loadingObj.type = 'done'
      commit('loadstateStore/setLoadState', loadingObj, { root: true });    
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  },

  getRelated({commit}, query) {
    var loadingObj = {
      content: 'related',
      type: 'new'
    };
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    this.$axios.get(process.env.API_URL + '/related-videos/' + query.id + '?include=' + query.include + '&type=video&frontend=' + process.env.FRONTEND_ID)
    .then(response => { 
      commit('setRelated', response.data);        
      loadingObj.type = 'done';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
    }).catch((error) => {
      console.log(error);
      loadingObj.type = 'error';
      commit('loadstateStore/setLoadState', loadingObj, { root: true });
      throw error;
    });
  }, 

}

export const getters = {
  VOTW(state) {
    return state.votw;
  },
  votw_new(state) {
    return state.votw_new;
  },
  votw_free(state) {
    return state.votw_free;
  },
  recommendedVideos(state) {
    return state.recommendedVideos;
  },
  topVideos(state) {
    return state.topVideos;
  },
  highlightVideos(state) {
    return state.highlightVideos;
  },
  video(state) {
    return state.video;
  },
  access(state) {
    return state.video.access;
  },
  related(state) {
    return state.related;
  },
  videosQuery(state) {
    return state.videosQuery;
  },
  highlightQuery(state) {
    return state.highlightQuery;
  },
  filter(state) {
    return state.filter;
  }
}

export const mutations = {
  setVOTW(state, data) {
    const video = getDeserializedData(data.data, data.included);

    video.title = video.title.replace("\\", "");

    video.images.forEach(function(element) {
      prosessVideoThumbs(video, element);
    });

    const sourceTypes = [
      { name: 'free_film', paymentProperty: 'video_free', setDuration: true },
      { name: 'film', paymentProperty: 'video_paid', setDuration: true },
      { name: 'trailer', paymentProperty: 'trailer', setDuration: false },
      ];

    if (video.videos) {
      for (const sourceType of sourceTypes) {
        setVideoContentWithDuration(video, sourceType.name, sourceType.paymentProperty, sourceType.setDuration);
      }
    }

    state.votw = video;
  },
  
  setAllVideos(state, data) {
    const videos = getDeserializedData(data.data, data.included);

    videos.forEach(function(video) { 
      video.images.forEach(function(element) {  
        prosessVideoThumbs(video, element);
      });

      const sourceTypes = [
        { name: 'free_film', paymentProperty: 'video_free', setDuration: true },
        { name: 'film', paymentProperty: 'video_paid', setDuration: true },
        { name: 'trailer', paymentProperty: 'trailer', setDuration: false },
        ];

      if (video.videos) {
        for (const sourceType of sourceTypes) {
          setVideoContentWithDuration(video, sourceType.name, sourceType.paymentProperty, sourceType.setDuration);
        }
      }
    });

    if (data.meta.pagination.current_page == 1) {
      state[data.type] = videos;
    } else {
      state[data.type] = state[data.type].concat(videos);
    }
  },

  setVideo(state, data) {
    const video = getDeserializedData(data.data, data.included);

    video.title = video.title.replace("\\", "");
    video.short = {};
    const shortDescription = video.description.substring(0, 180) + '... <a>Lees meer</a>';
    video.short.tablet = shortDescription;
    video.short.desktop = shortDescription;

    video.gallery = [];
    video.images.forEach(function(element) {
      prosessVideoThumbs(video, element);

      if (element.types === 'album') {
        video.gallery.push(process.env.CDN + element.path); 
      }
    });

    video.products.forEach(function(element) { 
      if (element.pivot_data && element.pivot_data.active_from) {
        const convertedDateStr = element.pivot_data.active_from.replace(' ', 'T') + 'Z';
        const date = new Date(convertedDateStr);
        video.uploadDate = format(date, "yyyy-MM-dd'T'HH:mm:ssXX");
      }
    });

    if (video.access && video.access.included) {
      video.access = getDeserializedData(video.access.data, video.access.data.included) || [];
    }

    const sourceTypes = [
      { name: 'free_film', paymentProperty: 'video_free' },
      { name: 'film', paymentProperty: 'video_paid' },
      { name: 'free_episode', paymentProperty: 'video_free' },
      { name: 'episode', paymentProperty: 'video_paid' }
      ];

    for (const sourceType of sourceTypes) {
      processVideoByType(video, sourceType.name, sourceType.paymentProperty);
    }

    if (video.videos && video.videos.trailer) {
      video.trailer = video.videos.trailer[0];
    }
    if (video.preroll && video.preroll.length > 0 && video.preroll[0].videos.preroll.length > 0) {
      video.has_preroll = true;
    }

    state.video = video;
  },

  setFilterActive(state) {
    state.filter.active = true;
  },

  setRelated(state, data) {
    const related = getDeserializedData(data.data, data.included);

    related.forEach(function(video) { 
      video.images.forEach(function(element) {  
        prosessVideoThumbs(video, element);
      });

      const sourceTypes = [
        { name: 'free_film', paymentProperty: 'video_free', setDuration: true },
        { name: 'film', paymentProperty: 'video_paid', setDuration: true },
        { name: 'trailer', paymentProperty: 'trailer', setDuration: false },
        ];

      if (video.videos) {
        for (const sourceType of sourceTypes) {
          setVideoContentWithDuration(video, sourceType.name, sourceType.paymentProperty, sourceType.setDuration);
        }
      }
    });

    state.related = related;
  },
  // TODO create one function for all queries
  updateQuery(state, query) {
    if (query.type == 'recurring' && query.id != 'del') {
      delete state.videosQuery.filter.products;
      state.videosQuery.filter.products = "1,2";
      delete state.videosQuery.filter.recurring;
      state.videosQuery.filter.recurring = 1;
      return;
    }
    if (query.type == 'products' && query.id == '1') {
      state.videosQuery.filter.products = "1"
      delete state.videosQuery.filter.recurring;
      return;
    }
    if (query.type == 'products' && query.id == '2') {
      state.videosQuery.filter.products = "2"
      delete state.videosQuery.filter.recurring;
      return;
    }
    if (query.type == 'sort') {
      state.videosQuery.sort = false;
      state.videosQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.videosQuery.page = query.name;
      if (query.name > 1) {
        state.videosQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.videosQuery.page = query.name;
    } else if(query.type == 'loadType') {
      state.videosQuery.loadType = query.name;
    } else {
      if (!query.id && query.type != 'recurring') {
        delete state.videosQuery.filter[query.type];
      } else {
        if (query.id == 'del') {
          delete state.videosQuery.filter.recurring;
        } else {
          if (query.id == 'favorites') {
            return state.videosQuery.filter[query.id] = 'video';  
          }
          state.videosQuery.filter[query.type] = query.id;
        }
      }
    }
  },
  updateFreeQuery(state, query) {
    if (query.type == 'sort') {
      state.freevideosQuery.sort = false;
      state.freevideosQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.freevideosQuery.page = query.name;
      if (query.name > 1) {
        state.freevideosQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.freevideosQuery.page = query.name;
    } else if(query.type == 'loadType') {
      state.freevideosQuery.loadType = query.name;
    } else {
      if (!query.id && query.type != 'recurring') {
        delete state.freevideosQuery.filter[query.type];
      } else {
        if (query.id == 'del') {
          delete state.freevideosQuery.filter.recurring;
        } else {
          state.freevideosQuery.filter[query.type] = query.id;
        }
      }
    }
  },

  updateModelVideosQuery(state, query) {
    if (query.type == 'recurring' && query.id != 'del') {
      delete state.modelVideosQuery.filter.products;
      state.modelVideosQuery.filter.products = "1,2";
      delete state.modelVideosQuery.filter.recurring;
      state.modelVideosQuery.filter.recurring = 1;
      return;
    }
    if (query.type == 'products' && query.id == '1') {
      state.modelVideosQuery.filter.products = "1"
      delete state.modelVideosQuery.filter.recurring;
      return;
    }
    if (query.type == 'products' && query.id == '2') {
      state.modelVideosQuery.filter.products = "2"
      delete state.modelVideosQuery.filter.recurring;
      state.modelVideosQuery.filter.recurring = 0;
      return;
    }
    if (query.type == 'sort') {
      state.modelVideosQuery.sort = false;
      state.modelVideosQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.modelVideosQuery.page = query.name;
      if (query.name > 1) {
        state.modelVideosQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.modelVideosQuery.page = query.name;
    } else if(query.type == 'loadType') {
      state.modelVideosQuery.loadType = query.name;
    } else {
      if (!query.id && query.type != 'recurring') {
        delete state.modelVideosQuery.filter[query.type];
      } else {
        if (query.id == 'del') {
          delete state.modelVideosQuery.filter.recurring;
        } else {
          state.modelVideosQuery.filter[query.type] = query.id;
        }
      }
    }
  },

  updateShowVideosQuery(state, query) {
    if (query.type == 'recurring' && query.id != 'del') {
      delete state.showVideosQuery.filter.products;
      state.showVideosQuery.filter.products = "1,2";
      delete state.showVideosQuery.filter.recurring;
      state.showVideosQuery.filter.recurring = 1;
      return;
    }
    if (query.type == 'products' && query.id == '1') {
      state.showVideosQuery.filter.products = "1"
      delete state.showVideosQuery.filter.recurring;
      return;
    }
    if (query.type == 'products' && query.id == '2') {
      state.showVideosQuery.filter.products = "2"
      delete state.showVideosQuery.filter.recurring;
      state.showVideosQuery.filter.recurring = 0;
      return;
    }
    if (query.type == 'sort') {
      state.showVideosQuery.sort = false;
      state.showVideosQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.showVideosQuery.page = query.name;
      if (query.name > 1) {
        state.showVideosQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.showVideosQuery.page = query.name;
    } else if(query.type == 'loadType') {
      state.showVideosQuery.loadType = query.name;
    } else {
      if (!query.id && query.type != 'recurring') {
        delete state.showVideosQuery.filter[query.type];
      } else {
        if (query.id == 'del') {
          delete state.showVideosQuery.filter.recurring;
        } else {
          state.showVideosQuery.filter[query.type] = query.id;
        }
      }
    }
  },

  updateCategoryVideosQuery(state, query) {
    if (query.type == 'recurring' && query.id != 'del') {
      delete state.categoryVideosQuery.filter.products;
      state.categoryVideosQuery.filter.products = "1,2";
      delete state.categoryVideosQuery.filter.recurring;
      state.categoryVideosQuery.filter.recurring = 1;
      return;
    }
    if (query.type == 'products' && query.id == '1') {
      state.categoryVideosQuery.filter.products = "1"
      delete state.categoryVideosQuery.filter.recurring;
      return;
    }
    if (query.type == 'products' && query.id == '2') {
      state.categoryVideosQuery.filter.products = "2"
      delete state.categoryVideosQuery.filter.recurring;
      state.categoryVideosQuery.filter.recurring = 0;
      return;
    }
    if (query.type == 'sort') {
      state.categoryVideosQuery.sort = false;
      state.categoryVideosQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.categoryVideosQuery.page = query.name;
      if (query.name > 1) {
        state.categoryVideosQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.categoryVideosQuery.page = query.name;
    } else if(query.type == 'loadType') {
      state.categoryVideosQuery.loadType = query.name;
    } else {
      if (!query.id && query.type != 'recurring') {
        delete state.categoryVideosQuery.filter[query.type];
      } else {
        if (query.id == 'del') {
          delete state.categoryVideosQuery.filter.recurring;
        } else {
          state.categoryVideosQuery.filter[query.type] = query.id;
        }
      }
    }
  },

  resetQuery(state) {
    let query = {
      type: 'new',
      loadType: 'videos',
      include: 'images:types(thumb|thumb_mobile),products,categories,clips',
      page: 1,
      count: 20,
      api_type: 'video',
      sort: {
        recommended_at: 'DESC'
      },
      filter: {
        status: 'published',
        products: '1,2'
      }
    };
    state.videosQuery = query;
  },

  updateFilterState(state, filterState) {
    state.filter[filterState.type] = filterState.name;
    if(!['Modellen', 'Taal', 'Genres', 'Type'].includes(filterState.name)) { 
      state.filter.activated[filterState.type] = filterState.name;
    } else {
      state.filter.activated[filterState.type] = false
    }
    state.filter.active = true;
    if (state.filter.models == 'Modellen' && state.filter.language == 'Taal' && state.filter.products == 'Type' && state.filter.categories == 'Genres') {
      state.filter.active = false;
    }
  },

  updateHighlightQuery(state, query) {
    state.highlightQuery.page = query.name;
  },
  setFilterActive(state) {
    state.filter.active = true;
  },
  updateFreeSortState(state, name) {
    state.freesort = name;
  },
  setPpmAccess(state, todo) {
    if (todo) {
      var dataObj = {};
      dataObj.product_id = 2;
      dataObj.short = "ppm";
      dataObj.subscription_id = 3;
      dataObj.sub_detail_id = 6;
      state.video.access = [];
      state.video.access.push(dataObj); 
    } else {
      state.video.access = false;
    }
  },
}
function getDeserializedData(dataInitial, dataIncluded) {
  const dataObj = [];
  dataObj.included = dataIncluded;
  dataObj.data = dataInitial;
  return deserialize(dataObj); 
}

function setVideoContentWithDuration(video, sourceType, paymentProperty, setDuration) {
  const videoArray = video.videos[sourceType];

  if (videoArray && videoArray[0]) {
    video[paymentProperty] = videoArray[0];

    if (setDuration && videoArray[0].duration) {
      video[paymentProperty].duration = video[paymentProperty].duration.substring(3, 5);
    }
  }
}

function processVideoByType(video, sourceType, paymentProperty) {
  if (video.videos && video.videos[sourceType]) {
    const sourceVideo = video.videos[sourceType][0];
    const clonedVideo = { ...sourceVideo };

    video[paymentProperty] = clonedVideo;
    video[paymentProperty].duration = video[paymentProperty].duration?.substring(3, 5) || '';


    const durationArray = sourceVideo.duration?.split(':') || [0, 0, 0]; // HHmmSS
    const seconds = (+durationArray[0]) * 60 * 60 + (+durationArray[1]) * 60 + (+durationArray[2]);

    video.seconds = seconds;
    video.isoDuration = formatISODuration({ hours: durationArray[0], minutes: durationArray[1], seconds: durationArray[2] });
  }
}

function prosessVideoThumbs(video, sourseElement) {
  if (sourseElement.types === 'thumb' || sourseElement.types == 'thumb_mobile') {
    video[sourseElement.types] = sourseElement.sizes;
    video[sourseElement.types].original = sourseElement.path;
  }
}