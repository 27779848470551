export const strict = false;

export const state = () => ({
  formStatus: {
    sending: false,
    complete: false,
    errors: {},
  }
})

export const actions = {
  sendForm(context, formdata) {
    var formStatus = [];
    formStatus.type = 'sending';
    formStatus.todo = true;
    context.commit('updateFormStatus', formStatus);
    var alertObj = {};
    
    this.$axios.post(process.env.API_URL + '/mail/send', formdata)
    .then(response => {
      formStatus.type = 'sending';
      formStatus.todo = false;
      context.commit('updateFormStatus', formStatus);

      formStatus.type = 'complete';
      formStatus.todo = true;
      context.commit('updateFormStatus', formStatus);
      if (typeof formdata.get === 'function') {
        var return_url = formdata.get('return_url');
        if (return_url) {
          var goto = window.location.protocol + '//' + window.location.host + window.location.pathname + return_url;
          return window.location = goto;
        }
      } 
      if (formdata.return_url) {
       var goto = window.location.protocol + '//' + window.location.host + window.location.pathname + formdata.return_url;
       return window.location = goto;
     }
     return true
   }).catch((error) => {
    console.log(error);
    alertObj.type = 'error';
    alertObj.message = 'Er is iets mis gegaan. Probeer het opnieuw of stuur een mailtje naar info@meidenvanholland.nl';
    context.commit('alertStore/setAlert', alertObj, { root: true });  

    formStatus.type = 'sending';
    formStatus.todo = false;
    context.commit('updateFormStatus', formStatus);

    formStatus.type = 'send';
    formStatus.text = true;
    context.commit('updateFormStatus', formStatus);

    this.app.$sentry.captureException(error);
  });
 },
 newsletter(context, formdata) {
  var formStatus = [];
  this.$axios.post(process.env.API_URL + '/users/subscribe', formdata)
  .then(response => {
    formStatus.type = 'sending';
    formStatus.todo = false;
    context.commit('updateFormStatus', formStatus);

    formStatus.type = 'complete';
    formStatus.todo = true;
    context.commit('updateFormStatus', formStatus);
    if (formdata.subscribe === 1) {
      if (formdata.route == 'acties-adventskalender') {
        var goto = window.location.protocol + '//' + window.location.host + window.location.pathname + '?newsletter=success';  
        window.location = goto;
        return;
      }
      var goto = window.location.protocol + '//' + window.location.host + window.location.pathname + '/bedankt';
    } else {
      var goto = window.location.protocol + '//' + window.location.host + window.location.pathname + '/uitgeschreven';
    }
    window.location = goto;

  }).catch((error) => {
    console.log(error);
    var alertObj = {};
    alertObj.type = 'error';
    alertObj.message = 'Er is iets mis gegaan. Probeer het opnieuw of stuur een mailtje naar info@meidenvanholland.nl';
    context.commit('alertStore/setAlert', alertObj, { root: true });  

    formStatus.type = 'sending';
    formStatus.todo = false;
    context.commit('updateFormStatus', formStatus);

    formStatus.type = 'send';
    formStatus.text = true;
    context.commit('updateFormStatus', formStatus);

    this.app.$sentry.captureException(error);
  });
}
}

export const mutations = {
  updateFormStatus(state, data) {
    state.formStatus[data.type] = data.todo;
  },
  updateErrors(state, data) {
    state.formStatus.errors[data.type] = data.text;
  },
  resetErrors(state) {
    state.formStatus.errors = {};
  },
  resetFormStatus(state) {
    state.formStatus = {
      sending: false,
      complete: false,
      errors: {},
    };
  }
}