import { deserialize } from 'jsonapi-deserializer';
import { differenceInDays } from 'date-fns'

export const strict = false;

export const state = () => ({
  notifications: false,
  newNotifications: 0,
  readCount: 0
})

export const actions = {
  async getNotifications({commit}, query) {
    await this.$axios.get(process.env.API_URL + '/notifications')
    .then(response => {
      commit('setNotifications', response.data);        
    }).catch((error) => {
      console.log(error);
    });
  },
  read({commit}, query) {
    this.$axios.post(process.env.API_URL + '/notifications/read-all')
    .then(response => { 
      commit('setReadAll');        
    }).catch((error) => {
      console.log(error);
    });
  },
}

export const getters = {
  notifications(state) {
    return state.notifications;
  },
}

export const mutations = {
  setNotifications(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var notifications = deserialize(dataObj);

    // moment.locale('nl'); // locale options was not used
    const now = new Date();
    notifications.forEach(function(notification) { 
      const days = Math.abs(differenceInDays(now, new Date(notification.active_from)));
      if (days == 0) {
        notification.active_from = 'vandaag';
      } else if (days == 1) {
        notification.active_from = '1 dag geleden';
      } else {
        notification.active_from = days + ' dagen geleden';
      }
      if (notification.read == 0) {
        state.newNotifications ++;
      }
    });
    state.notifications = notifications;
  },
  setReadAll(state, data) {
    var notifications = state.notifications;

    notifications.forEach(function(notification) { 
      notification.read = 1;
      state.newNotifications = 0;
    });
    state.notifications = notifications;
  },
  readThroughCookie(state, data) {
    var notifications = state.notifications;
    notifications.forEach(function(notification) {
      if (data.id == notification.id) {
        notification.read = 1;
        state.readCount ++;
      }
    });
    if (state.readCount == notifications.length) {
      state.newNotifications = 0;
    }
    state.notifications = notifications;
  },
}