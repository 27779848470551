//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { slugToTitle } from '@/helpers/filters.js';

import { mapState } from 'vuex';
export default {
  props: ['slug', 'name', 'type'],
  computed: {
    ...mapState({
      resource_item: ({ loadstateStore: { resource_item } }) => resource_item,
      playerState: ({ mxmStore: { playerState } }) => playerState,
    }),
  },
  methods: {
    slugToTitle,
  },
};
