// Use to check if a modal popup is active

export const strict = false;

export const state = () => ({
  dropdown: false
})

export const getters = {
  dropdown(state) {
    return state.dropdown;
  }
}

export const mutations = {
  setDropdown(state, dropdown) {
    state.dropdown = dropdown;
  }
}