export default (context, inject) => {
    let config = { 
        'baseUrl': process.env.BASE_URL,
        'feUrl': process.env.FE_URL,
        'apiUrl': process.env.API_URL,
        'appTitle': process.env.APP_TITLE,
        'frontendName': process.env.THEME,
        'frontendId': process.env.FRONTEND_ID,
        'cdn': process.env.CDN,
        'errorimg': process.env.ERROR_IMG,
        'errorimgSQ': process.env.ERROR_IMG_SQ,
        'errorepg': process.env.ERROR_EPG,
        'socketKey': process.env.SOCKET_KEY,
        'whitelabel': process.env.WHITELABEL,
        'midhold': process.env.MIDHOLD,
        'direct': process.env.DIRECT,
        'callNow': process.env.CALLNOW,
        'newsletter': process.env.NEWSLETTER,
        'webcamP': process.env.WEBCAM_P,
        'webcamPi': process.env.WEBCAM_PI,
        'webcamName': process.env.WEBCAM_NAME,
        'webcamUrl': process.env.WEBCAM_URL,
        'webcamUrlMobile': process.env.WEBCAM_URL_MOBILE
    }
    inject('config', config)
}