import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a7de992e = () => interopDefault(import('../pages/aanmelden.vue' /* webpackChunkName: "pages/aanmelden" */))
const _5edb370b = () => interopDefault(import('../pages/betaald.vue' /* webpackChunkName: "pages/betaald" */))
const _39bbc6ca = () => interopDefault(import('../pages/betalen/index.vue' /* webpackChunkName: "pages/betalen/index" */))
const _53aeceba = () => interopDefault(import('../pages/direct/index.vue' /* webpackChunkName: "pages/direct/index" */))
const _beb4f53e = () => interopDefault(import('../pages/genres/index.vue' /* webpackChunkName: "pages/genres/index" */))
const _4386db5a = () => interopDefault(import('../pages/gratis-sexfilms/index.vue' /* webpackChunkName: "pages/gratis-sexfilms/index" */))
const _eb4fb7c4 = () => interopDefault(import('../pages/hollandszaad/index.vue' /* webpackChunkName: "pages/hollandszaad/index" */))
const _a5e502fc = () => interopDefault(import('../pages/live-meekijken.vue' /* webpackChunkName: "pages/live-meekijken" */))
const _490457dd = () => interopDefault(import('../pages/modellen/index.vue' /* webpackChunkName: "pages/modellen/index" */))
const _cf6a45d0 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _72372831 = () => interopDefault(import('../pages/overzicht/index.vue' /* webpackChunkName: "pages/overzicht/index" */))
const _29191627 = () => interopDefault(import('../pages/privefilmpjes/index.vue' /* webpackChunkName: "pages/privefilmpjes/index" */))
const _5857d7c8 = () => interopDefault(import('../pages/series/index.vue' /* webpackChunkName: "pages/series/index" */))
const _05da2b8e = () => interopDefault(import('../pages/sexcamper/index.vue' /* webpackChunkName: "pages/sexcamper/index" */))
const _3779041a = () => interopDefault(import('../pages/sexfilms/index.vue' /* webpackChunkName: "pages/sexfilms/index" */))
const _0dfa1825 = () => interopDefault(import('../pages/sexmetkerst.vue' /* webpackChunkName: "pages/sexmetkerst" */))
const _54f22541 = () => interopDefault(import('../pages/tv-gids/index.vue' /* webpackChunkName: "pages/tv-gids/index" */))
const _e3eebda0 = () => interopDefault(import('../pages/webcamsex/index.vue' /* webpackChunkName: "pages/webcamsex/index" */))
const _0ed3e9a5 = () => interopDefault(import('../pages/zoekresultaten/index.vue' /* webpackChunkName: "pages/zoekresultaten/index" */))
const _27b07ece = () => interopDefault(import('../pages/account/direct.vue' /* webpackChunkName: "pages/account/direct" */))
const _3c16d939 = () => interopDefault(import('../pages/account/gegevens-voorkeuren.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren" */))
const _1d444fc8 = () => interopDefault(import('../pages/account/gehuurd.vue' /* webpackChunkName: "pages/account/gehuurd" */))
const _7de1a746 = () => interopDefault(import('../pages/account/mijn-credits.vue' /* webpackChunkName: "pages/account/mijn-credits" */))
const _1f0ce910 = () => interopDefault(import('../pages/account/mijn-favorieten.vue' /* webpackChunkName: "pages/account/mijn-favorieten" */))
const _4f476114 = () => interopDefault(import('../pages/acties/adventskalender/index.vue' /* webpackChunkName: "pages/acties/adventskalender/index" */))
const _15658ca0 = () => interopDefault(import('../pages/acties/droomvrouw-lexa/index.vue' /* webpackChunkName: "pages/acties/droomvrouw-lexa/index" */))
const _3054cdde = () => interopDefault(import('../pages/acties/energie-opwekkers/index.vue' /* webpackChunkName: "pages/acties/energie-opwekkers/index" */))
const _02561233 = () => interopDefault(import('../pages/acties/goudenei/index.vue' /* webpackChunkName: "pages/acties/goudenei/index" */))
const _2a84653c = () => interopDefault(import('../pages/acties/inmeikiesjij/index.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/index" */))
const _29442836 = () => interopDefault(import('../pages/acties/kamasutra-2024/index.vue' /* webpackChunkName: "pages/acties/kamasutra-2024/index" */))
const _494e16a3 = () => interopDefault(import('../pages/acties/live-meekijken/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/index" */))
const _029ce3b2 = () => interopDefault(import('../pages/acties/parkeerplaatssex/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/index" */))
const _ece88eec = () => interopDefault(import('../pages/acties/sexmetkerst/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/index" */))
const _0b30e06c = () => interopDefault(import('../pages/acties/valentijn/index.vue' /* webpackChunkName: "pages/acties/valentijn/index" */))
const _12fa2c0f = () => interopDefault(import('../pages/acties/webcamsex.vue' /* webpackChunkName: "pages/acties/webcamsex" */))
const _20fe6783 = () => interopDefault(import('../pages/acties/xl-deals-2024.vue' /* webpackChunkName: "pages/acties/xl-deals-2024" */))
const _d084d2dc = () => interopDefault(import('../pages/betalen/credits.vue' /* webpackChunkName: "pages/betalen/credits" */))
const _3da7ccbe = () => interopDefault(import('../pages/betalen/direct.vue' /* webpackChunkName: "pages/betalen/direct" */))
const _2d1e53e8 = () => interopDefault(import('../pages/betalen/huurcredits.vue' /* webpackChunkName: "pages/betalen/huurcredits" */))
const _e23c8762 = () => interopDefault(import('../pages/betalen/mvh-direct.vue' /* webpackChunkName: "pages/betalen/mvh-direct" */))
const _814583d8 = () => interopDefault(import('../pages/gebruikers/kijkgeschiedenis.vue' /* webpackChunkName: "pages/gebruikers/kijkgeschiedenis" */))
const _3b2636fa = () => interopDefault(import('../pages/hollandszaad/actie-voorwaarden.vue' /* webpackChunkName: "pages/hollandszaad/actie-voorwaarden" */))
const _0e690f16 = () => interopDefault(import('../pages/hollandszaad/betaald.vue' /* webpackChunkName: "pages/hollandszaad/betaald" */))
const _5c026fb7 = () => interopDefault(import('../pages/hollandszaad/cadeau.vue' /* webpackChunkName: "pages/hollandszaad/cadeau" */))
const _db1386ae = () => interopDefault(import('../pages/hollandszaad/groeikaart.vue' /* webpackChunkName: "pages/hollandszaad/groeikaart" */))
const _37a0eb62 = () => interopDefault(import('../pages/hollandszaad/verstuurd.vue' /* webpackChunkName: "pages/hollandszaad/verstuurd" */))
const _4e93aff7 = () => interopDefault(import('../pages/info/aanmelden/index.vue' /* webpackChunkName: "pages/info/aanmelden/index" */))
const _7d4a0c78 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _26258690 = () => interopDefault(import('../pages/info/contact/index.vue' /* webpackChunkName: "pages/info/contact/index" */))
const _20de37ab = () => interopDefault(import('../pages/info/feedback/index.vue' /* webpackChunkName: "pages/info/feedback/index" */))
const _408b2143 = () => interopDefault(import('../pages/info/nieuwsbrief/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/index" */))
const _6f383e77 = () => interopDefault(import('../pages/info/over.vue' /* webpackChunkName: "pages/info/over" */))
const _3fd54d96 = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _87768838 = () => interopDefault(import('../pages/info/sex-link-partners.vue' /* webpackChunkName: "pages/info/sex-link-partners" */))
const _54c88a9b = () => interopDefault(import('../pages/info/tv-zender-ontvangen.vue' /* webpackChunkName: "pages/info/tv-zender-ontvangen" */))
const _67f6274a = () => interopDefault(import('../pages/info/uitschrijven/index.vue' /* webpackChunkName: "pages/info/uitschrijven/index" */))
const _7bd8cb48 = () => interopDefault(import('../pages/info/veelgestelde-vragen.vue' /* webpackChunkName: "pages/info/veelgestelde-vragen" */))
const _ce8ce62e = () => interopDefault(import('../pages/sexcamper/aanmeldformulier/index.vue' /* webpackChunkName: "pages/sexcamper/aanmeldformulier/index" */))
const _55e2b3b1 = () => interopDefault(import('../pages/acties/inmeikiesjij/gekozen.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/gekozen" */))
const _612381bf = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/index" */))
const _04349490 = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/index" */))
const _215f0ab8 = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/index" */))
const _6976c910 = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/index" */))
const _c2fd8a2a = () => interopDefault(import('../pages/info/aanmelden/aanmeldformulier/index.vue' /* webpackChunkName: "pages/info/aanmelden/aanmeldformulier/index" */))
const _4160b815 = () => interopDefault(import('../pages/info/contact/bedankt.vue' /* webpackChunkName: "pages/info/contact/bedankt" */))
const _70b18e70 = () => interopDefault(import('../pages/info/feedback/bedankt.vue' /* webpackChunkName: "pages/info/feedback/bedankt" */))
const _58ca7208 = () => interopDefault(import('../pages/info/nieuwsbrief/bedankt.vue' /* webpackChunkName: "pages/info/nieuwsbrief/bedankt" */))
const _26672746 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/index" */))
const _c2cef5ac = () => interopDefault(import('../pages/info/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/uitschrijven/uitgeschreven" */))
const _5f0c8c2e = () => interopDefault(import('../pages/sexcamper/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/sexcamper/aanmeldformulier/bedankt" */))
const _9666b8f8 = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/bedankt" */))
const _503773d6 = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/bedankt" */))
const _5449d23d = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/bedankt" */))
const _1b0e32fd = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/bedankt" */))
const _11a0c1b0 = () => interopDefault(import('../pages/info/aanmelden/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/info/aanmelden/aanmeldformulier/bedankt" */))
const _0af7c926 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/uitgeschreven" */))
const _755adf98 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c22237ce = () => interopDefault(import('../pages/genres/_slug.vue' /* webpackChunkName: "pages/genres/_slug" */))
const _46f41dea = () => interopDefault(import('../pages/gratis-sexfilms/_slug.vue' /* webpackChunkName: "pages/gratis-sexfilms/_slug" */))
const _474db695 = () => interopDefault(import('../pages/modellen/_slug.vue' /* webpackChunkName: "pages/modellen/_slug" */))
const _d2d78860 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _708086e9 = () => interopDefault(import('../pages/overzicht/_slug.vue' /* webpackChunkName: "pages/overzicht/_slug" */))
const _11159822 = () => interopDefault(import('../pages/privefilmpjes/_slug/index.vue' /* webpackChunkName: "pages/privefilmpjes/_slug/index" */))
const _56a13680 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _10cbf856 = () => interopDefault(import('../pages/sexfilms/_slug/index.vue' /* webpackChunkName: "pages/sexfilms/_slug/index" */))
const _b5ee0f2a = () => interopDefault(import('../pages/webcamsex/_slug/index.vue' /* webpackChunkName: "pages/webcamsex/_slug/index" */))
const _007b85f5 = () => interopDefault(import('../pages/privefilmpjes/_slug/betalen.vue' /* webpackChunkName: "pages/privefilmpjes/_slug/betalen" */))
const _5793d0e8 = () => interopDefault(import('../pages/sexfilms/_slug/betalen.vue' /* webpackChunkName: "pages/sexfilms/_slug/betalen" */))
const _5b4ff363 = () => interopDefault(import('../pages/webcamsex/_slug/live.vue' /* webpackChunkName: "pages/webcamsex/_slug/live" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanmelden",
    component: _a7de992e,
    name: "aanmelden"
  }, {
    path: "/betaald",
    component: _5edb370b,
    name: "betaald"
  }, {
    path: "/betalen",
    component: _39bbc6ca,
    name: "betalen"
  }, {
    path: "/direct",
    component: _53aeceba,
    name: "direct"
  }, {
    path: "/genres",
    component: _beb4f53e,
    name: "genres"
  }, {
    path: "/gratis-sexfilms",
    component: _4386db5a,
    name: "gratis-sexfilms"
  }, {
    path: "/hollandszaad",
    component: _eb4fb7c4,
    name: "hollandszaad"
  }, {
    path: "/live-meekijken",
    component: _a5e502fc,
    name: "live-meekijken"
  }, {
    path: "/modellen",
    component: _490457dd,
    name: "modellen"
  }, {
    path: "/nieuws",
    component: _cf6a45d0,
    name: "nieuws"
  }, {
    path: "/overzicht",
    component: _72372831,
    name: "overzicht"
  }, {
    path: "/privefilmpjes",
    component: _29191627,
    name: "privefilmpjes"
  }, {
    path: "/series",
    component: _5857d7c8,
    name: "series"
  }, {
    path: "/sexcamper",
    component: _05da2b8e,
    name: "sexcamper"
  }, {
    path: "/sexfilms",
    component: _3779041a,
    name: "sexfilms"
  }, {
    path: "/sexmetkerst",
    component: _0dfa1825,
    name: "sexmetkerst"
  }, {
    path: "/tv-gids",
    component: _54f22541,
    name: "tv-gids"
  }, {
    path: "/webcamsex",
    component: _e3eebda0,
    name: "webcamsex"
  }, {
    path: "/zoekresultaten",
    component: _0ed3e9a5,
    name: "zoekresultaten"
  }, {
    path: "/account/direct",
    component: _27b07ece,
    name: "Direct"
  }, {
    path: "/account/gegevens-voorkeuren",
    component: _3c16d939,
    name: "Gegevens & voorkeuren"
  }, {
    path: "/account/gehuurd",
    component: _1d444fc8,
    name: "Gehuurd"
  }, {
    path: "/account/mijn-credits",
    component: _7de1a746,
    name: "Mijn credits"
  }, {
    path: "/account/mijn-favorieten",
    component: _1f0ce910,
    name: "Mijn favorieten"
  }, {
    path: "/acties/adventskalender",
    component: _4f476114,
    name: "acties-adventskalender"
  }, {
    path: "/acties/droomvrouw-lexa",
    component: _15658ca0,
    name: "acties-droomvrouw-lexa"
  }, {
    path: "/acties/energie-opwekkers",
    component: _3054cdde,
    name: "acties-energie-opwekkers"
  }, {
    path: "/acties/goudenei",
    component: _02561233,
    name: "acties-goudenei"
  }, {
    path: "/acties/inmeikiesjij",
    component: _2a84653c,
    name: "acties-inmeikiesjij"
  }, {
    path: "/acties/kamasutra-2024",
    component: _29442836,
    name: "acties-kamasutra-2024"
  }, {
    path: "/acties/live-meekijken",
    component: _494e16a3,
    name: "acties-live-meekijken"
  }, {
    path: "/acties/parkeerplaatssex",
    component: _029ce3b2,
    name: "acties-parkeerplaatssex"
  }, {
    path: "/acties/sexmetkerst",
    component: _ece88eec,
    name: "acties-sexmetkerst"
  }, {
    path: "/acties/valentijn",
    component: _0b30e06c,
    name: "acties-valentijn"
  }, {
    path: "/acties/webcamsex",
    component: _12fa2c0f,
    name: "acties-webcamsex"
  }, {
    path: "/acties/xl-deals-2024",
    component: _20fe6783,
    name: "acties-xl-deals-2024"
  }, {
    path: "/betalen/credits",
    component: _d084d2dc,
    name: "betalen-credits"
  }, {
    path: "/betalen/direct",
    component: _3da7ccbe,
    name: "betalen-direct"
  }, {
    path: "/betalen/huurcredits",
    component: _2d1e53e8,
    name: "betalen-huurcredits"
  }, {
    path: "/betalen/mvh-direct",
    component: _e23c8762,
    name: "betalen-mvh-direct"
  }, {
    path: "/gebruikers/kijkgeschiedenis",
    component: _814583d8,
    name: "gebruikers-kijkgeschiedenis"
  }, {
    path: "/hollandszaad/actie-voorwaarden",
    component: _3b2636fa,
    name: "hollandszaad-actie-voorwaarden"
  }, {
    path: "/hollandszaad/betaald",
    component: _0e690f16,
    name: "hollandszaad-betaald"
  }, {
    path: "/hollandszaad/cadeau",
    component: _5c026fb7,
    name: "hollandszaad-cadeau"
  }, {
    path: "/hollandszaad/groeikaart",
    component: _db1386ae,
    name: "hollandszaad-groeikaart"
  }, {
    path: "/hollandszaad/verstuurd",
    component: _37a0eb62,
    name: "hollandszaad-verstuurd"
  }, {
    path: "/info/aanmelden",
    component: _4e93aff7,
    name: "info-aanmelden"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _7d4a0c78,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/contact",
    component: _26258690,
    name: "info-contact"
  }, {
    path: "/info/feedback",
    component: _20de37ab,
    name: "info-feedback"
  }, {
    path: "/info/nieuwsbrief",
    component: _408b2143,
    name: "info-nieuwsbrief"
  }, {
    path: "/info/over",
    component: _6f383e77,
    name: "info-over"
  }, {
    path: "/info/privacy",
    component: _3fd54d96,
    name: "info-privacy"
  }, {
    path: "/info/sex-link-partners",
    component: _87768838,
    name: "info-sex-link-partners"
  }, {
    path: "/info/tv-zender-ontvangen",
    component: _54c88a9b,
    name: "info-tv-zender-ontvangen"
  }, {
    path: "/info/uitschrijven",
    component: _67f6274a,
    name: "info-uitschrijven"
  }, {
    path: "/info/veelgestelde-vragen",
    component: _7bd8cb48,
    name: "info-veelgestelde-vragen"
  }, {
    path: "/sexcamper/aanmeldformulier",
    component: _ce8ce62e,
    name: "sexcamper-aanmeldformulier"
  }, {
    path: "/acties/inmeikiesjij/gekozen",
    component: _55e2b3b1,
    name: "acties-inmeikiesjij-gekozen"
  }, {
    path: "/acties/live-meekijken/aanmeldformulier",
    component: _612381bf,
    name: "acties-live-meekijken-aanmeldformulier"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier",
    component: _04349490,
    name: "acties-parkeerplaatssex-aanmeldformulier"
  }, {
    path: "/acties/sexmetkerst/aanmeldformulier",
    component: _215f0ab8,
    name: "acties-sexmetkerst-aanmeldformulier"
  }, {
    path: "/acties/valentijn/aanmeldformulier",
    component: _6976c910,
    name: "acties-valentijn-aanmeldformulier"
  }, {
    path: "/info/aanmelden/aanmeldformulier",
    component: _c2fd8a2a,
    name: "info-aanmelden-aanmeldformulier"
  }, {
    path: "/info/contact/bedankt",
    component: _4160b815,
    name: "info-contact-bedankt"
  }, {
    path: "/info/feedback/bedankt",
    component: _70b18e70,
    name: "info-feedback-bedankt"
  }, {
    path: "/info/nieuwsbrief/bedankt",
    component: _58ca7208,
    name: "info-nieuwsbrief-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven",
    component: _26672746,
    name: "info-nieuwsbrief-uitschrijven"
  }, {
    path: "/info/uitschrijven/uitgeschreven",
    component: _c2cef5ac,
    name: "info-uitschrijven-uitgeschreven"
  }, {
    path: "/sexcamper/aanmeldformulier/bedankt",
    component: _5f0c8c2e,
    name: "sexcamper-aanmeldformulier-bedankt"
  }, {
    path: "/acties/live-meekijken/aanmeldformulier/bedankt",
    component: _9666b8f8,
    name: "acties-live-meekijken-aanmeldformulier-bedankt"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier/bedankt",
    component: _503773d6,
    name: "acties-parkeerplaatssex-aanmeldformulier-bedankt"
  }, {
    path: "/acties/sexmetkerst/aanmeldformulier/bedankt",
    component: _5449d23d,
    name: "acties-sexmetkerst-aanmeldformulier-bedankt"
  }, {
    path: "/acties/valentijn/aanmeldformulier/bedankt",
    component: _1b0e32fd,
    name: "acties-valentijn-aanmeldformulier-bedankt"
  }, {
    path: "/info/aanmelden/aanmeldformulier/bedankt",
    component: _11a0c1b0,
    name: "info-aanmelden-aanmeldformulier-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven/uitgeschreven",
    component: _0af7c926,
    name: "info-nieuwsbrief-uitschrijven-uitgeschreven"
  }, {
    path: "/",
    component: _755adf98,
    name: "index"
  }, {
    path: "/genres/:slug",
    component: _c22237ce,
    name: "genres-slug"
  }, {
    path: "/gratis-sexfilms/:slug?",
    component: _46f41dea,
    name: "gratis-sexfilms-slug"
  }, {
    path: "/modellen/:slug",
    component: _474db695,
    name: "modellen-slug"
  }, {
    path: "/nieuws/:slug",
    component: _d2d78860,
    name: "nieuws-slug"
  }, {
    path: "/overzicht/:slug",
    component: _708086e9,
    name: "overzicht-slug"
  }, {
    path: "/privefilmpjes/:slug",
    component: _11159822,
    name: "privefilmpjes-slug"
  }, {
    path: "/series/:slug",
    component: _56a13680,
    name: "series-slug"
  }, {
    path: "/sexfilms/:slug",
    component: _10cbf856,
    name: "sexfilms-slug"
  }, {
    path: "/webcamsex/:slug",
    component: _b5ee0f2a,
    name: "webcamsex-slug"
  }, {
    path: "/privefilmpjes/:slug/betalen",
    component: _007b85f5,
    name: "privefilmpjes-slug-betalen"
  }, {
    path: "/sexfilms/:slug/betalen",
    component: _5793d0e8,
    name: "sexfilms-slug-betalen"
  }, {
    path: "/webcamsex/:slug/live",
    component: _5b4ff363,
    name: "webcamsex-slug-live"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
