import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';
import { isBefore, isSameDay, isAfter } from 'date-fns';

export const strict = false;

export const state = () => ({
  advents: false,
  advent: false,
  event: false,
  slug: '',
  complete: false
})

export const actions = {
  async getAdvents({commit}, query) {
    let sort = serialize.serializeSort(query.sort);    
    let filters = serialize.serializeFilter(query.filters);   
    await this.$axios.get(process.env.API_URL + '/others?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort + '&type=advent' + '&frontend_id=1&quick_notation=true')
    .then(response => { 
      commit('setAdvents', response.data);        
    }).catch((error) => {
      console.log(error);
    });
  },
  event(context, query) {
    this.$axios.post(process.env.API_URL + '/event-log', query)
    .then(response => {      
      if (query.form) {
        context.dispatch('formStore/sendForm', query.form, { root: true });    
        return true;
      }
      if (query.subQuery) {
        context.dispatch('subscriptionStore/subscribe', query.subQuery, { root: true });
        return true;
      }
      var alertObj = {};
      if (response.data.message && response.data.message == 'exist') {
        alertObj.type = 'info';
        alertObj.message = 'Je doet al mee aan deze actie, lieverd.';
        return context.commit('alertStore/setAlert', alertObj, { root: true });
      } else {
        alertObj.type = 'succes';
        alertObj.message = 'We hebben je e-mailadres ontvangen!';
        context.commit('alertStore/setAlert', alertObj, { root: true });
        context.commit('modalStore/setModal', false, { root: true });
      }
      context.commit('setComplete', true);  
    }).catch((error) => {
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Klik op deze melding om contact op te nemen';
      alertObj.href = '/info/contact';
      return context.commit('alertStore/setAlert', alertObj, { root: true });
    });
  },
  async getEvent({commit}, query) {
    await this.$axios.get(process.env.API_URL + '/event-log?type=' + query.type)
    .then(response => {      
      commit('setEvent', response.data);     
    }).catch((error) => {
      console.log(error);
      var alertObj = {};
      alertObj.type = 'error';
      alertObj.message = 'Er is iets mis gegaan. Klik op deze melding om contact op te nemen';
      alertObj.href = '/info/contact';
      return commit('alertStore/setAlert', alertObj, { root: true });
    });
  },
}

export const getters = {
  advents(state) {
    return state.advents;
  },
  advent(state) {
    return state.advent;
  },
  event(state) {
    return state.event;
  }
}

export const mutations = {
  setAdvents(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var advents = deserialize(dataObj);

    const now = new Date();
    advents.forEach(function(advent) { 
      const active = new Date(advent.frontend.recommended_at);
      if(isBefore(now, active)) {
        advent.status = 'before';
      }
      if(isSameDay(now, active)) {
        advent.status = 'active';
      }
      if(isAfter(now, active)) {
        advent.status = 'after';
      }
      if (advent.slug == 'sexfilm-actie') {
       advent.status = 'active'; 
     }
   });

    state.advents = advents;
  },
  setEvent(state, data) {  
    state.event = JSON.parse(data.data[0].value);
  },
  setAdvent(state, data) {  
    state.advent = data;
  },
  setSlug(state, slug) { 
    state.slug = slug;
  },
  setComplete(state, todo) { 
    state.complete = todo;
  }
}