import { deserialize } from 'jsonapi-deserializer';
import { serialize } from 'assets/js/serialize.js';

export const strict = false;

export const state = () => ({
  shows: [],
  show: [],
  episode: [],

  showsQuery: {
    include: 'images:types(thumb):limit(1|0),products,categories',
    page: 1,
    count: 50,
    sort: {
      published_at: 'DESC'
    },
    filters: {
      status: 'published',
    }
  },

  sort: 'Meest recent'
})

export const actions = {
 async getShows({commit}, query) {
  var loadingObj = {
    content: 'shows',
    type: 'new'
  };
  commit('loadstateStore/setLoadState', loadingObj, { root: true });

  let sort = serialize.serializeSort(query.sort);    
  let filters = serialize.serializeFilter(query.filters);    
  await this.$axios.get(process.env.API_URL + '/shows?page=' + query.page + '&count=' + query.count + '&include=' + query.include + filters + sort)
  .then(response => { 
    commit('setShows', response.data);        
    loadingObj.type = 'done';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
  }).catch((error) => {
    loadingObj.type = 'error';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    throw error;
  });
},
async getShow({commit}, query) {
  var loadingObj = {
    content: 'show',
    type: 'new'
  };
  commit('loadstateStore/setLoadState', loadingObj, { root: true });

  let filters = serialize.serializeFilter(query.filter);    
  let sort = serialize.serializeSort(query.sort);    
  let user_id = '';
  if (query.user_id) {
    user_id = '&user_id=' + query.user_id;    
  }
  await this.$axios.get(process.env.API_URL + '/shows/' + query.slug + '?include=' + query.include + filters + sort + user_id)
  .then(response => {
    commit('setShow', response.data);
    loadingObj.type = 'done'
    commit('loadstateStore/setLoadState', loadingObj, { root: true });    
  }).catch((error) => {
    loadingObj.type = 'error';
    commit('loadstateStore/setLoadState', loadingObj, { root: true });
    throw error;
  });
},
}

export const getters = {
  shows(state) {
    return state.shows;
  },
  show(state) {
    return state.show;
  },
  episode(state) {
    return state.episode;
  },
  showsQuery(state) {
    return state.showsQuery;
  },
}

export const mutations = {
  setShows(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;

    var shows = deserialize(dataObj);    

    shows.forEach(function(show) { 
      show.images.forEach(function(element) { 
        if (element.types == 'thumb') {
          show.thumb = element.sizes;
          show.thumb.original = element.path;
        }
        if (element.types == 'thumb_mobile') {
          show.thumb_mobile = element.sizes;
          show.thumb_mobile.original = element.path;
        }
      });
    });

    shows.pagination = data.meta.pagination;

    state.shows = shows;
  },
  setShow(state, data) {
    var dataObj = [];
    dataObj.included = data.included;
    dataObj.data = data.data;
    var show = deserialize(dataObj);

    show.title = show.title.replace("\\", "");

    show.short = {};
    show.short.mobile = show.description.substring(0, 250) + '... <a>Lees meer</a>';
    show.short.tablet = show.description.substring(0, 50) + '... <a>Lees meer</a>';
    show.short.desktop = show.description.substring(0, 250) + '... <a>Lees meer</a>';

    state.show = show;
    state.episode = show.episodes[0];
  },
  setEpisode(state, episode) {
    state.episode = episode;
  },
  updateQuery(state, query) {
    if (query.type == 'sort') {
      state.showsQuery.sort = false;
      state.showsQuery.sort = {
        [query.name]: query.dir
      }
    } else if(query.type == 'page') {
      state.showsQuery.page = query.name;
      if (query.name > 1) {
        state.showsQuery.type = 'map';
      }
    } else if(query.type == 'page') {
      state.showsQuery.page = query.name;
    } else if(!query.name && !query.id) {
      state.showsQuery.filter = {
        status: 'published',
        products: '1,2',
        types: 'show'
      }
    } else {
      state.showsQuery.filter[query.type] = query.id;
    }
  },
  updateSortState(state, name) {
    state.sort = name;
  },
}